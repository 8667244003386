import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-select-widget',
  templateUrl: './sf-select-widget.component.html'
})
export class SelectWidgetComponent extends ControlWidget {
}
