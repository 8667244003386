import {Component} from '@angular/core';

import {ObjectLayoutWidget} from '../../widget';

@Component({
  selector: 'app-sf-form-object',
  templateUrl: './sf-object-widget.component.html',
})
export class ObjectWidgetComponent extends ObjectLayoutWidget {
}
