import {Injectable} from '@angular/core';
import {MabbleModuleWorkflow, StepComponent, WorkflowStep} from '../model/workflow.model';
import {
  FormCaptureComponentModel, FormDefinitionComponentModel, QuestionAnswerComponentModel,
  QuestionAnswerOptionComponentModel,
  QuestionComponentModel
} from '@forms/models/form-capture-component.model';
import {WorkflowService} from './workflow.service';
import {HttpClient} from '@angular/common/http';
import {ServiceSupportService} from '@services/service-support.service';
import {ApplicationSessionService} from '@services/application-session.service';

@Injectable()
export class TigerWorkflowService extends ServiceSupportService {

  constructor(public workflowService: WorkflowService,
              protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    super(sessionService, http);
  }

  findQuestion(questionId: string, formDefinition: FormDefinitionComponentModel): QuestionComponentModel {
    let detQuestion: QuestionComponentModel;
    formDefinition.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question._mblId === questionId) {
          detQuestion = question;
        }
      });
    });
    return detQuestion;
  }

  grantAppEnablingFoodGarden(workflow): boolean {
    let res = false;
    workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
      if (!res && wfStep.stepName === 'grant-application') {
        wfStep.stepComponents.forEach((stepComp: StepComponent) => {
          // get the first question (toggle) that controls rendering Food Garden
          if (!res && stepComp.component_MblId === 'f845c3dd-7a93-4ca1-9cb3-d1b174f9040c') { // Grant Application
            if (stepComp.componentData) {
              const formDef: FormDefinitionComponentModel = new FormDefinitionComponentModel(stepComp.componentData.dataBody.definition);
              const question: QuestionComponentModel = this.findQuestion('232dd12c-42e4-4892-b02f-7065894ee42b', formDef);
              if (question.answer[0].answerValue) {
                question.answer[0].answerValue.forEach((qaoc: QuestionAnswerOptionComponentModel) => {
                  if (qaoc.label === 'Household, Community and Campus Food Garden Initiative') {
                    if (qaoc.selected) {
                      res = true;
                    }
                  }
                });
              }

            }
          }
        });
      }
    });
    return res;
  }

  univNutritionEnablingFoodGarden(workflow): boolean {
    let res = false;
    workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
      if (!res && wfStep.stepName === 'grant-application') {
        wfStep.stepComponents.forEach((stepComp: StepComponent) => {
          // get the second question (toggle) that controls it
          if (!res && stepComp.component_MblId === '9a6ba4ae-ad3f-42fd-b5c0-cfa3b022501d') { // University Nutrition Support Programme
            if (stepComp.componentData) {
              const formDef: FormDefinitionComponentModel = new FormDefinitionComponentModel(stepComp.componentData.dataBody.definition);
              const question: QuestionComponentModel = this.findQuestion('d233fc57-ccd5-4c47-b55b-6c96ed636f82', formDef);


              console.log(
                '%c *** mzs *** - tiger-workflow.univNutritionEnablingFoodGarden:',
                'color: yellow; background-color: blue;',
                question.answer[0].answerValue);


              if (question.answer[0].answerValue && question.answer[0].answerValue === 'Yes') {
                res = true;
              }
            }
          }
        });
      }
    });
    return res;
  }

}
