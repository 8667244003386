import {FormCapture} from './form-capture.model';
import {FormDefinitionOptions, GridQuestion, QuestionCategory, ValidationRules} from '../form-definition.model';
import {MBL_KEY_FORM_DEFINITION, MblObjectFormControl, Named, ObjectEntity, OE, uuidv4} from '@core/models/application-session.model';

const nameSort = (a, b) => (a.name || 0) - (b.name || 0);
const orderSort = (a, b) => (a.order || 0) - (b.order || 0);
const sequenceSort = (a, b) => (a.sequence || 0) - (b.sequence || 0);


export function mblFormCaptureTable(fca: Array<any>, definedQuestions: any[]): FormCaptureResponseTable {
  const result: FormCaptureResponseTable = new FormCaptureResponseTable();
  (fca || []).forEach((fc: any, ix) => {
    const capture = new FormCaptureComponentModel(fc);
    const questions: QuestionAnswerItem[] = mblQuestions(capture, definedQuestions) || [];
    if (ix === 0) {
      result.capture = capture;
      result.columns = questions.map((questionLineItem) => {
        return {
          prop: questionLineItem.dtx + '.answer',
          name: questionLineItem.question,
          key: questionLineItem.dtx,
          qt: questionLineItem.qt,
          at: questionLineItem.at
        };
      });
      result.section_count = capture.definition && capture.definition.sections && capture.definition.sections.length;
      result.question_count = questions.length;
      result.question_answers = [];
    }
    result.question_answers.push(questions.reduce((previousValue, currentValue) => {
      // const derived = (currentValue.answer && currentValue.answer[0] && currentValue.answer[0]['answerValue'])
      //   || currentValue.answer && currentValue.answer.answerValue
      //   || currentValue.answer && currentValue.answer.value
      //   || currentValue.answer;
      // this.logger.trace('yay it is an answer of stuff... typeof [' + (typeof derived) + ']', derived);
      previousValue[currentValue.dtx] = {
        questionLineItem: currentValue,
        answer: currentValue.answer
      };
      return previousValue;
    }, {}));
  });
  return result;
}

export function mblQuestions(fc: any, definedQuestions: any[]): QuestionAnswerItem[] {
  const results = [];
  if (fc) {
    if (fc.definition && fc.definition.sections && fc.definition.sections.length > 0) {
      fc.definition.sections.sort(sequenceSort);
      fc.definition.sections.forEach((section, six) => {
        if (section.questions && section.questions.length > 0) {
          section.questions.sort(sequenceSort);
          section.questions.forEach((question, qix) => {
            // if no defined questions are given,  lets just assume all?
            if (!definedQuestions || !definedQuestions.length
              || definedQuestions[0] === 0 || definedQuestions.indexOf(question._mblId) > -1) {


              // (((question.answer && question.answer[0])
              //   && question.answer[0]['answerValue'] || question.answer) || question.answer);
              let derived = (question.answer && question.answer[0] && question.answer[0]['answerValue'])
                || question.answer && question.answer.answerValue
                || question.answer.value
                || question.answer;

              // 🐊🐊🐊
              // this.logger.trace('mblQuestions: question answer typed as: ' + (typeof  derived) + '', derived);
              // this.logger.trace('mblQuestions: question is', question);
              if (typeof derived === 'object') {
                // this.logger.trace('mblQuestions: question answer 🐊');
                derived = undefined;
                // this.logger.trace('mblQuestions: question answer 🐊🐊');
              }

              if (typeof derived === 'symbol') {
                // this.logger.trace('mblQuestions: question answer 🐊🐊🐊');
              }

              const dtx: string = 'q-' + six + '-' + qix;
              results.push(new QuestionAnswerItem({
                context_mblId: fc._mblId,
                formDefinition_mblId: fc.definition._mblId,
                section_mblId: section._mblId,
                question_mblId: question._mblId,
                dtx: dtx,
                question: question.label,
                six: six,
                qix: qix,
                qt: question.questionType,
                at: question.answerType,
                answer: derived
              }));
            }
          });
        }
      });
    } else { // csv upload
      // this.logger.trace('fc dataloader for csv', fc);
      Object.keys(fc).forEach((colkey, kix) => {
        const dtx: string = 'pd-q-' + '-' + kix;
        results.push(new QuestionAnswerItem({
          dtx: dtx,
          question: colkey,
          six: 1,
          qix: kix,
          answer: fc[colkey]
        }));
      });
    }
  }
  return results;
}

export class FormulaTokenAlias {
  variable: string;
  alias: string;
  disabled = false;
  form_mblId: string;
  question_mblId: string;
  grid_reference?: string;
  questionText: string;

  constructor(options?: any) {
    this.variable = options && options.variable;
    this.alias = options && options.alias;
    this.form_mblId = options && options.form_mblId;
    this.question_mblId = options && options.question_mblId;
    this.grid_reference = options && options.grid_reference;
    this.questionText = options && options.questionText;
  }
}

/**
 * Defines the complete [Questionnaire/HTML Form] meta data needed for a form capture;
 */
export class FormDefinitionComponentModel extends ObjectEntity<any> implements OE, Named {
  name?: string;
  description?: string;
  options?: FormDefinitionOptions;
  sections?: Array<FormSectionComponentModel>;
  questionCategories?: any[];

  public static hasSections(model: FormDefinitionComponentModel): boolean {
    return model && model.sections && model.sections.length > 0;
  }

  constructor(options?: any) {
    super(options);

    this.context = this._mblId;
    this.identifier = '' + this.id;
    this.type = MBL_KEY_FORM_DEFINITION;

    this.name = options && options.name;
    this.description = options && options.description;
    this.options = options && options.options;
    this.sections = [];
    this.questionCategories = options && options.questionCategories || [];

    const i = options && options.sections || [];
    if (i && i.length > 0) {
      i.forEach(itm => this.sections.push(new FormSectionComponentModel(itm)));
      this.sections.sort(sequenceSort);
    }
  }

  public findQuestion(mblId: string) {
    let result: QuestionComponentModel;
    this.forEachSection((section) => {
      if (!result || result._mblId) {
        section.forEachQuestion((question => {
          if (question._mblId === mblId) {
            result = question;
          }
        }))
      }
    });
    return result;
  }

  public forEachQuestion(cb: any): void {
    if (this.sections && this.sections.length > 0) {
      this.sections.forEach(section => {
        section.forEachQuestion(cb);
      });
    }
  }

  public forEachSection(cb: any): void {
    if (this.sections && this.sections.length > 0) {
      this.sections.forEach(cb);
    }
  }

  get hasSections(): boolean {
    return this.sections && this.sections.length > 0;
  }
}

export class FormSectionComponentModel extends ObjectEntity<any> implements OE {
  description?: string;
  label?: string;
  questions?: Array<QuestionComponentModel>;
  sequence?: number;

  constructor(options?: any) {
    super(options);
    this.description = options && options.description;
    this.label = options && options.label;
    this.sequence = options && options.sequence;

    if (options && Object.keys(options).length > 0) {
      this.questions = [].concat((options.questions || []).map(q => new QuestionComponentModel(q)));
      this.questions.sort(sequenceSort);
    }
  }

  public forEachQuestion(cb: any): void {
    if (this.questions && this.questions.length > 0) {
      this.questions.forEach(cb);
    }
  }

}

export class QuestionComponentModel extends ObjectEntity<any> implements OE {
  activity?: string;
  allowComments?: boolean;
  answer?: Array<AnswerComponentModel>;
  answerOptionsSource: string;
  answerType?: string;
  boolQTlabel1?: string;
  boolQTlabel2?: string;
  categories?: Array<QuestionCategory>;
  code?: string;
  formula?: string;
  formulaCalculation?: boolean;
  formulaRender?: boolean;
  formulaTokenMap: { [key: string]: FormulaTokenAlias };
  gridQuestion?: GridQuestion;
  helpText?: string;
  label?: string;
  nestedGridHeadings: boolean;
  partnersIndicator: boolean;
  questionAnswerOptions?: Array<QuestionAnswerOptionComponentModel>;
  questionType?: string;
  sequence?: number;
  sourceCodesFileCode?: string;
  sourceQuestionId?: string;
  validationRules?: ValidationRules;
  weighting?: number;
  inputPrefix?: string;
  inputSuffix?: string;
  editorType?: string;


  dataTableOptions?: {
    multiSelect: boolean;
    dataOptionsSource?: string;
    dataOptionsContext?: string;
  };
  dateOptions?: {
    minDate?: {
      year: number;
      month: number;
      day: number;
    };
  };
  regionalSelectionOptions?: {
    multiSelect?: boolean;
  };
  sliderOptions?: {
    enabled: boolean,
    max: number,
    min: number,
    step?: number
  };
  tagsOptions?: {
    allowAdd?: boolean;
    allowShare?: boolean;
    multiSelect?: boolean;
  };
  yornOptions?: {
    yesColor: string,
    yesLabel: string,
    noColor: string,
    noLabel: string,
    maybeColor: string,
    allowNotApplicable: boolean
  };


  systemName?: string;
  indexAttributeName?: string;

  constructor(options?: any) {
    super(options);
    this.activity = options && options.activity || this.activity;
    this.allowComments = options && options.allowComments || this.allowComments;
    this.answer = options && options.answer || [] || this.answer;
    this.answerOptionsSource = options && options.answerOptionsSource || this.answerOptionsSource;
    this.answerType = options && options.answerType || this.answerType;
    this.boolQTlabel1 = options && options.boolQTlabel1 || this.boolQTlabel1;
    this.boolQTlabel2 = options && options.boolQTlabel2 || this.boolQTlabel2;
    this.categories = options && options.categories || [] || this.categories;
    this.code = options && options.code || this.code;
    this.dateOptions = options && options.dateOptions || {minDate: {year: 1950, month: 1, day: 1}};
    this.formula = options && options.formula || this.formula;
    this.formulaCalculation = (options && options.formulaCalculation) || true || this.formulaCalculation;
    this.formulaRender = (options && options.formulaRender) || false || this.formulaRender;
    this.formulaTokenMap = options && options.formulaTokenMap || this.formulaTokenMap || {};
    this.gridQuestion = options && options.gridQuestion || this.gridQuestion;
    this.label = options && options.label || this.label;
    this.nestedGridHeadings = options && options.nestedGridHeadings || this.nestedGridHeadings;
    this.partnersIndicator = options && options.partnersIndicator || this.partnersIndicator;
    this.questionAnswerOptions = options && options.questionAnswerOptions || [] || this.questionAnswerOptions;
    this.questionType = options && options.questionType || this.questionType;
    this.sequence = options && options.sequence || this.sequence;

    this.dataTableOptions = options && options.dataTableOptions || this.dataTableOptions || {
      enabled: false,
      multiSelect: false,
      dataOptionsSource: undefined,
      dataOptionsContext: undefined
    };

    this.regionalSelectionOptions = options && options.regionalSelectionOptions || this.regionalSelectionOptions || {multiSelect: false};
    this.sliderOptions = options && options.sliderOptions || this.sliderOptions || {enabled: false, max: 10, min: 0, step: 1};
    this.yornOptions = options && options.yornOptions || this.yornOptions || {
      yesColor: '#70c66b',
      yesLabel: 'Yes',
      noColor: '#70c66b',
      noLabel: 'No',
      maybeColor: '#dbdbdb',
      allowNotApplicable: false
    };
    this.tagsOptions = options && options.tagsOptions || this.tagsOptions || {
      allowAdd: false,
      allowShare: false,
      multiSelect: false
    };
    this.systemName = options && options.systemName || this.label;
    this.indexAttributeName = options && options.indexAttributeName || undefined;

    this.sourceCodesFileCode = options && options.sourceCodesFileCode;
    this.sourceQuestionId = options && options.sourceQuestionId;
    this.validationRules = options && options.validationRules || new ValidationRules();
    this.helpText = options && options.helpText;
    this.weighting = options && options.weighting;

    this.inputPrefix = options && options.inputPrefix;
    this.inputSuffix = options && options.inputSuffix;
    this.editorType = options && options.editorType;

    this.formulaTokenMap = {
      'alias_null': new FormulaTokenAlias({})
    };
    if (options && options.formulaTokenMap && Object.keys(options.formulaTokenMap).length > 0) {
      Object.keys(options.formulaTokenMap).forEach(k => this.formulaTokenMap[k] = options.formulaTokenMap[k]);
    }
  }

  public initialiseQuestionAnswerModel(options ?: any): AnswerComponentModel[] {
    const reply: AnswerComponentModel[] = [];
    if (options && options.definition) {
      const qc = options.definition.questionCategories && options.definition.questionCategories.length > 0
        ? [...options.definition.questionCategories]
        : ['none'];
      qc.forEach(answerCategory => {
        reply.push(new AnswerComponentModel({
          answerCategory: answerCategory,
          answerValue: {}
        }));
      });
    }
    return reply;
  }
}

export class FormCaptureElementGridComponentModel {
  nestedHeadings?: boolean;
}

export class FormCaptureComponentModel extends ObjectEntity<FormCaptureComponentModel> {
  capture?: FormCapture;
  captureStatus?: string;     // Submitted, ...?
  controls: MblObjectFormControl[];
  definition?: FormDefinitionComponentModel;
  support?: { gridOptions?: FormCaptureElementGridComponentModel; checklists?: Array<any>; };

  public static hasSectionData(model: FormCaptureComponentModel): boolean {
    const fc = new FormCaptureComponentModel(model);
    return fc && fc.hasSections;
  }

  constructor(options?: any) {
    super(options);
    this.capture = options && options.capture || this.capture;
    this.definition = options && options.definition && new FormDefinitionComponentModel(options.definition) || this.definition;
    this.captureStatus = options && options.captureStatus || this.captureStatus;
    this.support = options && options.support || {
      gridOptions: new FormCaptureElementGridComponentModel(),
      checklists: []
    };
  }

  get hasSections(): boolean {
    return this.definition && this.definition.hasSections;
  }

  // this.captureResponseTable = options && options.captureResponseTable;
  get buildCaptureResponseTable(): FormCaptureResponseTable {
    return mblFormCaptureTable([this], []);
  }
}

export class FormCaptureResponseTable {
  id: string;
  capture?: FormCaptureComponentModel | any;
  columns?: any[];
  section_count?: number;
  question_count?: number;
  question_answers?: any[];

  constructor(options?: any) {
    this.id = options && options.id || uuidv4();
    this.capture = options && options.capture;
    this.columns = options && options.columns;
    this.section_count = options && options.section_count;
    this.question_count = options && options.question_count;
    this.question_answers = options && options.question_answers;
  }

}

export class CaptureConfigModel extends ObjectEntity<CaptureConfigModel> implements OE {
  view: {
    btnBack?: boolean;
    btnCancel?: boolean;
    btnDelete?: boolean;
    btnSave?: boolean;
    btnSubmit?: boolean;
    btnValidate?: boolean;
    formBottomText?: boolean;
    formButtons?: boolean;
    headerCard?: boolean;
  };

  constructor(options?: any) {
    super(options);
    this.view = options && options.view;
  }
}

export class QuestionAnswerItem {
  dtx: string;
  six?: number;
  qix?: number;
  qt?: string;
  at?: string;
  answer?: any;
  question?: string;
  question_mblId?: string;
  section_mblId?: string;
  formDefinition_mblId?: string;
  context_mblId?: string;

  constructor(options?: any) {
    this.dtx = options && options.dtx || uuidv4();
    this.six = options && options.six;
    this.qix = options && options.qix;
    this.qt = options && options.qt;
    this.at = options && options.at;
    this.at = options && options.at;
    this.answer = options && options.answer;
    this.question = options && options.question;

    this.question_mblId = options && options.question_mblId;
    this.section_mblId = options && options.section_mblId;
    this.formDefinition_mblId = options && options.formDefinition_mblId;
    this.context_mblId = options && options.context_mblId;

    this[this.dtx] = this.answer;
  }
}

export interface FormulaTokenAlias {
  variable: string;
  alias: string;
  form_mblId: string;
  question_mblId: string;
  questionText: string;
}

export class AnswerComponentModel extends ObjectEntity<AnswerComponentModel> implements OE {
  answerCategory?: string;
  /**
   * radio - option value
   * checkbox - [options + true/false]
   * yorn - "yes"/"no"/"unconfirmed"
   * text - "value"
   * number - value (also currency, which will be dropped back to number soon)
   * date - value
   * dropdown - selected value
   * dropdown-cascaded:  the extended FormCaptureElementSelectCascadeModel
   * grid - table of [row][col][value] nb: nested header...
   */
  answerValue?: any;
  answerComments?: string;
  answerError?: string;
  answerMeta?: any;
  notApplicable: boolean;

  constructor(options?: any) {
    super(options);
    if (options) {
      this.answerCategory = options.answerCategory;
      this.answerValue = options.answerValue;
      this.answerComments = options.answerComments;
      this.answerError = options.answerError;
      this.answerMeta = options.answerMeta;
      this.notApplicable = options.notApplicable || false;
    }
  }
}

export class SkipRules extends ObjectEntity<SkipRules> implements OE {
  allRules?: Array<FormSkipRules>;
  private index?: any = {};

  constructor(options?: any) {
    super(options);
    this.allRules = options && options.allRules || [];
    this.index = options && options.index || {};
  }

  private indexByKey() {
    this.index = {};
    if (this.allRules) {
      this.allRules.map(formSkipRules => {
        if (formSkipRules.formRules) {
          formSkipRules.formRules.map(questionSkipRules => {
            if (questionSkipRules.questionRules) {
              questionSkipRules.questionRules.map(skipRule => {
                if (skipRule.questionsToSkip) {
                  skipRule.questionsToSkip.map(questionToSkip => {
                    this.index['ix-' + questionToSkip.srFormId + '.' + questionToSkip.srQuestionId] = skipRule;
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  resolve(key: string): SkipRule {
    if (Object.keys(this.index).length === 0) {
      this.indexByKey();
    }
    return this.index[key];
  }

  flush() {
    this.index = {};
  }
}

export class FormSkipRules {
  formId: string;
  formRules?: Array<QuestionSkipRules>;
}

export class QuestionSkipRules {
  formId: string;
  questionId: string;
  questionRules?: Array<SkipRule>;
}

export class SkipRule {
  formId: string;
  questionId: string;
  ruleName: string;
  includeRule: boolean;
  answerValue: any;
  questionsToSkip: Array<QuestionToSkip>;
}

export class QuestionToSkip {
  srFormId: string;
  srQuestionId: string;
}


export class FormCaptureElementSelectCascadeModel extends AnswerComponentModel {
  meta: {
    key: string;
    label: string;
    level: number;
    options: any[];
    selected: string;
  };

  constructor(options?: any) {
    super(options);
    this.meta = options && options.meta;
  }
}


export class QuestionAnswerComponentModel {
  value?: any;
  label?: any;
  options?: any[];

  constructor(options?: any) {
    this.label = options && options.label || this.label;
    this.value = options && options.value || this.value;
    this.options = options && options.options || this.options;
  }
}

// {_mblId: uuidv4(), disabled: false, label: name, structure: {}, tag: true}
export class QuestionAnswerOptionComponentModel {
  _mblId?: string;

  answer?: any;
  disabled: boolean; // --- IOption
  id?: number;
  label: string; // --- IOption
  questionId?: string;
  selected?: boolean;
  sequence?: number;
  tag = false;
  weighting?: number;

  /*
   * A structure can be provided to allow for cascaded select options...
   * each item providing it's own child hierarchy.
   */
  structure?: any;

  constructor(options?: any) {
    this._mblId = this._mblId || uuidv4();

    this.answer = options && options.answer;
    this.disabled = options && options.disabled || false;
    this.id = options && options.id;
    this.label = options && options.label || options.description || options.name || 'None Selected';
    this.questionId = options && options.questionId;
    this.selected = options && options.selcted || this.selected;
    this.sequence = options && options.sequence || options && options.order;
    this.structure = options && options.structure || {};
    this.weighting = options && options.weighting;
  }

  // --- IOption implementation
  get value(): string {
    return this.label || String(this._mblId);
  }

  // --- IOption implementation
  set value(value: string) {
    this.answer = value;
  }

}

export class GridQuestionAnswerComponentModel extends QuestionAnswerComponentModel {
  row: number;
  column: number;
}

export class AnswerGridIndicesModel {
  rowIndex: number;
  colIndex: number;
  value: any;
}

export class GridHeadingsModel {
  value: string;
  name: string;
}


