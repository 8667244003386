import {Injectable} from '@angular/core';
import {SkipRules} from '@forms/models/form-capture-component.model';
import {ObjectEntity} from '@core/models/application-session.model';
import {ObjectEntityService} from '@services/object-entity.service';

@Injectable()
export class WfSkipRulesService {

  private _allSkipRules: SkipRules;

  constructor(private oeService: ObjectEntityService) {
  }

  getAllSkipRules(): Promise<SkipRules> {
    return this.getOEskipRules().then((skipRulesOE: ObjectEntity<any>) => {
      this._allSkipRules = new SkipRules(skipRulesOE);
      return this._allSkipRules;
    });
  }

  getOEskipRules(): Promise<any> {
    return this.oeService.getByType('skip-rules').toPromise().then(payload => {
      return payload[0];
    });
  }


  reset() {
    this._allSkipRules = undefined;
  }
}
