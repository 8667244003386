import {Component} from '@angular/core';

import {ArrayLayoutWidget} from '../../widget';

@Component({
  selector: 'app-sf-array-widget',
  templateUrl: './sf-array-widget.component.html'
})
export class ArrayWidgetComponent extends ArrayLayoutWidget {

  addItem() {
    this.formProperty.addItem();
  }

  removeItem(index: number) {
    this.formProperty.removeItem(index);
  }

  trackByIndex(index: number, item: any) {
    return index;
  }
}
