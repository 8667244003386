export {ArrayWidgetComponent}       from './array/sf-array-widget.component';
export {CheckboxWidgetComponent}    from './checkbox/sf-checkbox-widget.component';
export {FileWidgetComponent}        from './file/sf-file-widget.component';
export {IntegerWidgetComponent}     from './integer/sf-integer-widget.component';
export {ObjectWidgetComponent}      from './object/sf-object-widget.component';
export {RadioWidgetComponent}       from './radio/sf-radio-widget.component';
export {RangeWidgetComponent}       from './range/sf-range-widget.component';
export {SelectWidgetComponent}      from './select/sf-select-widget.component';
export {StringWidgetComponent}      from './string/sf-string-widget.component';
export {TextAreaWidgetComponent}    from './textarea/sf-textarea-widget.component';
export {SchemaFormWidgetRegistry}      from './defaultwidgetregistry';
