import {AppComponent} from './app.component';
import {ApplicationSessionService} from '@services/application-session.service';
import {AuthenticatedGuard} from '@shared/authenticated.guard';
import {AuthenticatedUserResolver} from '@user/resolvers/authenticated-user-resolver.service';
import {AuthService} from '@services/auth.service';
import {BootstrapModalModule} from 'ng2-bootstrap-modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '@environments/environment';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {FormlyModule} from '@ngx-formly/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {LookupCodesService} from '@services/lookup-codes.service';
import {MabbleWidgetRegistry} from './mabble-module/mabble-widget-registry';
import {ModalAlertComponent} from '@shared/modal/modal-alert/modal-alert.component';
import {ModalConfirmComponent} from '@shared/modal/modal-confirm/modal-confirm.component';
import {ModalConfirmInputComponent} from '@shared/modal/modal-confirm-input/modal-confirm-input.component';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ObjectEntityAllResolverService} from '@resolvers/object-entity-all-resolver.service';
import {ObjectEntityService} from '@services/object-entity.service';
import {OeReportingService} from '@services/reporting/oe-reporting.service';
import {OpenLandingRoutesGuard} from '@core/open-landing-routes.guard';
import {QuillTypeComponent} from './formly-stuff/quill-type.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegistrationService} from '@services/registration.service';
import {RepeatTypeComponent} from './formly-stuff/repeat-type.component';
import {RoleService} from '@services/role-service';
import {RolesResolver} from '@resolvers/roles-resolver.service';
import {RouteInterceptorService} from '@core/interceptors/route-interceptor-service';
import {RouterModule, Routes} from '@angular/router';
import {SchemaFormWidgetRegistry} from './schema-form/components/defaultwidgets';
import {SidebarModule} from 'ng-sidebar';
import {ToastrModule} from 'ngx-toastr';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UscGaWorkflowService} from '@workflow/components/usc-ga-workflow/usc-ga-workflow.service';
import {UserInterfaceConfigurationService} from '@services/user-interface-configuration.service';
import {WfSkipRulesService} from '@workflow/services/wf-skip-rules.service';
import {WordpressApiService} from '@services/wordpress-api.service';
import {WorkflowService} from '@workflow/services/workflow.service';
import {LookupGeoService} from '@services/lookup-geo.service';
import {TigerWorkflowService} from '@workflow/services/tiger-workflow.service';
import {DragulaModule} from 'ng2-dragula';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function minLengthValidationMessage(err, field) {
  return `Should have at least ${field.templateOptions.minLength} characters`;
}

export function maxLengthValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err, field) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.max}`;
}

const ROUTE_DEBUG_TRACING = false;
const ROUTES: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/app/pub/landing'},
  {path: 'app', loadChildren: './mabble-instance/mabble-instance.module#MabbleInstanceModule'},
  {path: 'authentication', redirectTo: '/app/authentication'},
  {path: 'kb-search', loadChildren: '@kbms/knowledge-base.module#KnowledgeBaseModule'},
  {path: 'error', redirectTo: '/app/error'},

  {path: 'spike', loadChildren: './spike/spike-scratch.module#SpikeScratchModule'},

  {path: '**', redirectTo: '/app/error/404'}
];

@NgModule({
  declarations: [
    AppComponent,
    ModalAlertComponent,
    ModalConfirmComponent,
    ModalConfirmInputComponent,
    RepeatTypeComponent,
    QuillTypeComponent,
  ],
  exports: [
    ModalAlertComponent,
    ModalConfirmComponent,
    ModalConfirmInputComponent,
    NgbModalModule,
    NgbModule,
    QuillTypeComponent,
    RepeatTypeComponent
  ],
  entryComponents: [
    ModalAlertComponent,
    ModalConfirmComponent,
    ModalConfirmInputComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    RouterModule.forRoot(ROUTES, {enableTracing: ROUTE_DEBUG_TRACING}),

    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF
    }),

    NgbModule.forRoot(),
    NgbModalModule,
    BootstrapModalModule.forRoot({container: document.body}),
    NgxDatatableModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 0,
      closeButton: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FormlyModule.forRoot({
      types: [
        {name: 'repeat', component: RepeatTypeComponent},
        {name: 'quill', component: QuillTypeComponent},
      ],
      validationMessages: [
        {name: 'required', message: 'This is a required value'},
        {name: 'minlength', message: minLengthValidationMessage},
        {name: 'maxlength', message: maxLengthValidationMessage},
        {name: 'min', message: minValidationMessage},
        {name: 'max', message: maxValidationMessage},
      ]
    }),
    FormlyBootstrapModule,

    DragulaModule

  ],
  providers: [
    ApplicationSessionService,
    AuthenticatedGuard,
    AuthenticatedUserResolver,
    AuthService,
    CookieService,
    LookupCodesService,
    LookupGeoService,
    ObjectEntityAllResolverService,
    ObjectEntityService,
    OeReportingService,
    OpenLandingRoutesGuard,
    RegistrationService,
    RoleService,
    RolesResolver,
    RouteInterceptorService,
    TigerWorkflowService,
    UscGaWorkflowService,
    UserInterfaceConfigurationService,
    WfSkipRulesService,
    WordpressApiService,
    WorkflowService,

    // {provide: jp, useValue: jp},
    // {provide: objectPath, useValue: objectPath},
    // {provide: HTTP_INTERCEPTORS, useClass: GhostAuthInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true},
    {provide: MabbleWidgetRegistry, useClass: SchemaFormWidgetRegistry}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
