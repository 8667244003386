import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ServiceSupportService} from './service-support.service';
import {ApplicationSessionService} from './application-session.service';
import {ApplicationSessionUser, User} from '@core/models/application-session.model';
import {fromPromise} from 'rxjs/internal/observable/fromPromise';

@Injectable()
export class AuthService extends ServiceSupportService {

  private url_self: string;


  constructor(protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_self = this.url_ + this.api.endpoints.self;
  }


  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    return this.tokenNotExpired(null, token);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }


  protected tokenNotExpired(param, token: string) {
    return true;
  }

  public logout(): void {
    this.user = new ApplicationSessionUser({}); // Build out the application user applied to this session.
    this.sessionService.set('session.auth.token', null);
    this.sessionService.set('session.user', this.user);
    return localStorage.setItem('token', null);
  }

  public self(credentials?: { username: string, password: string }): Observable<User> {
    let token: string = null;
    if (credentials) {
      token = btoa(credentials.username + ':' + credentials.password);
    }

    if (!token && this.getToken()) {
      token = this.getToken();
    }

    if (!token && this.user && this.user.auth_token) {
      token = this.user.auth_token;
    }

    if (!token && this.user && this.user.data && this.user.data.ba) {
      token = this.user.data.ba;
    }

    if (!token && this.user && this.user.data && this.user.data.username && this.user.data.password) {
      token = btoa(this.user.data.username + ':' + this.user.data.password);
    }


    if (token) {
      this.sessionService.set('session.auth.token', token);
      const headers = this.getAuthenticatedHeaders();
      return fromPromise(this.http
        .get<User>(this.url_self, {headers})
        .toPromise()
        .then((self: User) => {
          this.user.auth_token = token;
          this.user.data = self;
          this.sessionService.set('session.user', this.user);
          return self;
        }));

    } else if (this.user && this.user.data) {
      return of(this.user.data);

    }
  }

  public getAuthenticatedHeaders(): HttpHeaders {
    const ah = this.getAuthorizationHeader();
    return ah
      ? ServiceSupportService.HTTP_HEADERS.set('Authorization', ah)
      : ServiceSupportService.HTTP_HEADERS.delete('Authorization');
  }

  getAuthorizationHeader(): string {
    if (this.user && this.user.auth_token) {
      return 'Basic ' + this.user.auth_token;
    }
    // if (this.auth.isAuthenticated()) {
    //   return 'Bearer ' + this.auth.getToken();
    // }
    return null;
  }

  public collectFailedRequest(request): void {
    // this.cachedRequests.push(request);
  }


}
