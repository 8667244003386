import {Injectable} from '@angular/core';
import {MabbleModuleWorkflow, StepComponent, WorkflowStep} from '../../model/workflow.model';
import {
  FormCaptureComponentModel, FormDefinitionComponentModel, QuestionAnswerComponentModel,
  QuestionComponentModel
} from '@forms/models/form-capture-component.model';
import {WorkflowService} from '../../services/workflow.service';
import {HttpClient} from '@angular/common/http';
import {ServiceSupportService} from '@services/service-support.service';
import {ApplicationSessionService} from '@services/application-session.service';

@Injectable()
export class UscGaWorkflowService extends ServiceSupportService {

  constructor(public workflowService: WorkflowService,
              protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    super(sessionService, http);
  }

  eligiblilityRules(currentWorkflowStep: WorkflowStep): string[] {
    const eligibilityMessages = {
      incorrectDistrict: `PEPFAR is focused on the highest burden districts in South Africa, which were listed on the previous page.
       You did not select any of these districts for implementation, therefore, you are not eligible to apply for this funding`,
      mustBeRegisteredNPO: 'Your organization must be a registered NPO, NPC or trust.',
      orgTooYoung: `In order to be eligible for funding, applicants need to be registered for at least two years.
       Your NPO registration date is less than two years old.`,
      alreadyABeneficiary: 'Your organization is currently a direct beneficiary of other U.S. Government funding sources.',
      alreadyPEPFARRecipient: 'Your organization is currently a sub-recipient of other PEPFAR funding.',
      notServingHIVCommunities: 'Your organization is not serving HIV affected/infected populations.',
      previouslyFunded: 'Your organization received funding from the HIV and AIDS Community Grants Program in the last funding cycle.',
    };

    // get the FormCapture
    const componentData = this.workflowService.getComponentDataById(currentWorkflowStep, 'b622802e-4f6e-422f-8967-c17089720aea');
    const componentCapture: FormCaptureComponentModel = componentData.dataBody;

    const elibilityFail = [];
    let question: QuestionComponentModel = this.findQuestion('8c35d8cb-beec-4ab2-ba90-a832c478336b', componentCapture.definition);
    if (question &&
      question.answer[0].answerValue[question.answer[0].answerValue.length - 1].label === 'None of the above' &&
      question.answer[0].answerValue[question.answer[0].answerValue.length - 1].value === true) {
      elibilityFail.push(eligibilityMessages.incorrectDistrict);
    }
    question = this.findQuestion('b1dd58d9-27ee-4a7b-8be0-4faff33b4fd4', componentCapture.definition);
    if (question && question.answer[0].answerValue === 'No') {
      elibilityFail.push(eligibilityMessages.mustBeRegisteredNPO);
    }

    question = this.findQuestion('0c897c1a-880f-4d70-92be-3a0066e17f64', componentCapture.definition);
    if (question && question.answer[0].answerValue) {
      const npoRegDate =
        new Date(question.answer[0].answerValue.year, question.answer[0].answerValue.month - 1, question.answer[0].answerValue.day);
      const npoTooYoungDate = new Date(new Date().getFullYear() - 2, 3, 1); // 1 April 2 years ago
      if (npoRegDate && npoRegDate > npoTooYoungDate) {
        elibilityFail.push(eligibilityMessages.orgTooYoung.replace('{date}', (
          npoTooYoungDate.getDate() + '/' +
          (npoTooYoungDate.getMonth() + 1) + '/' +
          npoTooYoungDate.getFullYear()
        )));
      }
    }

    question = this.findQuestion('a37611a5-1903-4ea7-8cbc-2d497d6e5279', componentCapture.definition);
    if (question && question.answer[0].answerValue === 'Yes') {
      elibilityFail.push(eligibilityMessages.alreadyABeneficiary);
    }
    question = this.findQuestion('d2a525f8-e1b8-483b-a575-60c8db65a637', componentCapture.definition);
    if (question && question.answer[0].answerValue === 'Yes') {
      elibilityFail.push(eligibilityMessages.alreadyPEPFARRecipient);
    }

    question = this.findQuestion('25387522-7e49-40c8-ba7a-aa50feaead24', componentCapture.definition);
    if (question && question.answer[0].answerValue === 'No') {
      elibilityFail.push(eligibilityMessages.notServingHIVCommunities);
    }

    if (this.currentlyHaveCgpFunding(componentCapture)) {
      elibilityFail.push(eligibilityMessages.previouslyFunded);
    }
    return elibilityFail;
  }

  findQuestion(questionId: string, formDefinition: FormDefinitionComponentModel): QuestionComponentModel {
    let detQuestion: QuestionComponentModel;
    formDefinition.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question._mblId === questionId) {
          detQuestion = question;
        }
      });
    });
    return detQuestion;
  }

  currentlyHaveCgpFunding(componentCapture): boolean {
    return this.fundingStartTooSoon(componentCapture, [
      '5ba84e52-4822-4b3c-ac60-11fa68389ab0',
      '42d795b4-d8a2-47e7-a672-0a69e2988265',
      '0da3a11b-da4e-4e4c-8e2f-c66653d29351',
      '088364c3-0a76-4d7b-969d-a348ea58de52',
      'fb7ff9fa-c752-4507-a852-2ce46be8f63e'
    ]);
  }


  fundingStartTooSoon(componentCapture, questionIds: string[]): boolean {
    let reply = false;
    if (questionIds && questionIds.length) {
      questionIds.forEach(value => {
        if (!reply) {
          const question = this.findQuestion(value, componentCapture.definition);
          if (question && question.answer && question.answer.length > 0) {
            const fundingStartDate =
              new Date(question.answer[0].answerValue.year, question.answer[0].answerValue.month - 1, question.answer[0].answerValue.day);
            const cutoffFundingDate = new Date(new Date().getFullYear() - 1, 9, 1); // 1 October 1 year ago
            reply = fundingStartDate && fundingStartDate >= cutoffFundingDate;
          }
        }
      });
    }
    return reply;
  }

  setServiceDeliveryComponents(currentWorkflowStep: WorkflowStep, workflow: MabbleModuleWorkflow) {
    // get the FormCapture
    const componentData = this.workflowService.getComponentDataById(currentWorkflowStep, '1f91dbc2-58dd-4631-af9c-34c1ed699887');
    const componentCapture: FormCaptureComponentModel = componentData.dataBody;
    const question: QuestionComponentModel = this.findQuestion('aca5b9d0-7597-4124-a4ff-5d28e62a34b2', componentCapture.definition);

    // depending on the types of service delivery programs selected, setup relevant components to active/inactive
    workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
      if (wfStep.stepName === 'app-form-2-complete') {
        wfStep.stepComponents.forEach((stepComp: StepComponent) => {
          switch (stepComp.componentName) {
            case 'children and youth':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Children and Youth') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'community care':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Community-based Care, Treatment and Support') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'prevention':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Prevention and Awareness Outreach') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            default:
              break;

          }
        })
      }
    });
  }

  setFundingRequestComponents(currentWorkflowStep: WorkflowStep, workflow: MabbleModuleWorkflow) {
    // get the FormCapture
    const componentData = this.workflowService.getComponentDataById(currentWorkflowStep, '4f3bf263-03f6-41ea-9870-f86117d202e8');
    const componentCapture: FormCaptureComponentModel = componentData.dataBody;
    const question: QuestionComponentModel = this.findQuestion('b8ed50f4-8623-4d9e-9475-05b2651087e4', componentCapture.definition);

    // depending on the types of service delivery programs selected, setup relevant components to active/inactive
    workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
      if (wfStep.stepName === 'app-form-1') {
        wfStep.stepComponents.forEach((stepComp: StepComponent) => {
          switch (stepComp.componentName) {
            case 'training 6a':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Training') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'support groups 6b':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Support groups (not specific to adherence)') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'adherence clubs 6c':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Adherence clubs') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'hiv testing 6d':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'HIV testing and counseling services') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'home care 6e':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Home based care') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'counseling 6f':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Counseling') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'community mobilisation 6g':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Community mobilisation (demand creation)') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'clinical services 6h':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Clinical services') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'after school 6i':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'After School Program or Drop In Center') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'early childhood 6j':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Early childhood development program') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'community clubs 6k':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Community clubs / dialogues / teams') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'camps 6l':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Camps (disclosure and / or life skills)') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'peer education 6m':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Peer education and / or mentorship') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'targeted prevention 6n':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Targeted prevention / education programs') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'system development 6o':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Programs, processes or system development for the organisation') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;
            case 'other 6p':
              question.answer[0].answerValue.forEach((answerOption: QuestionAnswerComponentModel) => {
                if (answerOption.label === 'Other - please specify in comment field below') {
                  if (answerOption.value) {
                    stepComp.componentStatus = 'active'
                  } else {
                    stepComp.componentStatus = 'inactive'
                  }
                }
              });
              break;

            default:
              break;

          }
        })
      }
    });
  }

  maxFundingAmount(currentWorkflowStep: WorkflowStep): number {
    let fundingAmount = 0;
    currentWorkflowStep.stepComponents.forEach((stepComp: StepComponent) => {
        switch (stepComp.component_MblId) {
          case 'f781c0e3-1884-4446-9616-7a297acf9d14': // Training
          case '482557e2-26e7-46ab-953e-b67f21231283': // Support groups (not specific to adherence)
          case '870b3364-c727-4acd-ac05-06056d072150': // Adherence clubs
          case 'a3856e6d-509e-4fbd-9088-6bee54279fb8': // HIV testing and counseling services
          case '5dd0a03c-4ad3-47cd-8cb2-2774d9be362b': // Home based care
          case '7111228d-2f69-44b9-8e9f-224482274f5b': // Counseling
          case '36c12b47-5b21-4632-ad66-7009ff93282f': // Community mobilisation (demand creation)
          case '595b48cc-999c-4b34-93ae-a1fe7668e3bf': // "Clinical services"
          case 'd167e255-25e3-4871-8dad-f579a357a2ab': // "After School Program or Drop In Center"
          case 'ea05f3f4-5dc7-4cc4-bc7a-e273d9ded506': // "Early childhood development program"
          case 'ecbbd340-596c-4446-85ad-9771f23caacf': // "Community clubs / dialogues / teams"
          case '3743534e-b8d8-4685-8793-aa5801820f28': // "Camps (disclosure and / or life skills)"
          case 'a7c5bff1-5a75-43ec-94de-5d8d978347e3': // "Peer education and / or mentorship" "a7c5bff1-5a75-43ec-94de-5d8d978347e3"
          case '07bbf2f3-7820-451a-ae27-15ffea3b1d9a': // "Targeted prevention / education programs"
          case 'c4ae1c4b-8652-42a6-88c1-b8dff3e90bad': // "Programs, processes or system development for the organisation"
          case '4503b64b-60e0-4fee-96c6-aa5c9324dcc6': // "Other - please specify in comment field below"
            if (stepComp.componentStatus === 'active') {
              const componentData = this.workflowService.getComponentDataById(currentWorkflowStep, stepComp.component_MblId);
              const componentCapture: FormCaptureComponentModel = componentData.dataBody;
              componentCapture.definition.sections.forEach((section) => {
                if (section.label.trim() === 'How much funding is being requested?') {
                  section.questions.forEach((question: QuestionComponentModel) => {
                    if (question.answer[0].answerValue) {
                      fundingAmount = fundingAmount + question.answer[0].answerValue
                    }
                  });
                }
              });
            }
            break;
        }
      }
    );
    return fundingAmount;
  }

}
