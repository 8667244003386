import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-string-widget',
  templateUrl: './sf-string-widget.component.html'
})
export class StringWidgetComponent extends ControlWidget {

  getInputType() {
    if (!this.schema.widget.id || this.schema.widget.id === 'string') {
      return 'text';
    } else {
      return this.schema.widget.id;
    }
  }

}
