import * as _ from 'lodash';
import * as hash from 'object-hash';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormCaptureComponentModel, FormDefinitionComponentModel} from '@forms/models/form-capture-component.model';
import {FormControl} from '@angular/forms';
import {TableColumn} from '@swimlane/ngx-datatable';
import {AssessmentDefinitionBuilderComponent} from '@sat/assessment-definition-builder/assessment-definition-builder.component';

//
export const MBL_CTX_ASSESSMENT_CAMPAIGN = 'mabble.context.assessment.campaign';
export const MBL_CTX_ASSESSMENT_DEFINITION = 'mabble.context.assessment.definition';
export const MBL_CTX_ASSESSMENT_DEFINITION_DOMAIN = 'mabble.context.assessment.definition.domain';
export const MBL_CTX_ASSESSMENT_DEFINITION_WORKFLOW = 'mabble.context.assessment.definition.workflow';
export const MBL_CTX_KBMS_CONFIGURATION = 'mabble.context.kbms.configuration';
export const MBL_CTX_FORM_QUESTION = 'mabble.context.form.capture.section.question';
export const MBL_CTX_META_CONSTANTS = 'mabble.context.meta.constants';
export const MBL_CTX_META_CONTENT_STATUS = {
  new: 'NEW',
  draft: 'DRAFT',
  published: 'PUBLISHED',
  archived: 'ARCHIVED'
};
export const MBL_CTX_SENDMSG = 'mabble.context.sendmsg';
export const MBL_CTX_USER = 'mabble.context.user';

export const MBL_USER_SOURCE_SELF = 'mabble.user.source.mabble';
export const MBL_USER_SOURCE_WP = 'mabble.user.source.wordpress';

export const MBL_TYPE_ASSESSMENT_CAMPAIGN = 'mabble.module.type.assessment.campaign';
export const MBL_TYPE_ASSESSMENT_CANDIDATE = 'mabble.type.assessment.candidate';
export const MBL_TYPE_ASSESSMENT_CANDIDATE_ASSESSMENT = 'mabble.type.assessment.candidate.assessment';
export const MBL_TYPE_ASSESSMENT_DEFINITION = 'mabble.module.type.assessment.definition';
export const MBL_TYPE_ASSESSMENT_DEFINITION_DOMAIN_FORM = 'mabble.module.type.assessment.definition.domain.form';
export const MBL_TYPE_CFGTST = 'mabble.type.test';
export const MBL_TYPE_CONTAINER = 'mabble.module.type.container';
export const MBL_TYPE_CONTENT = 'mabble.module.type.content';
export const MBL_TYPE_DATALOADER = 'mabble.module.type.dataloader';
export const MBL_TYPE_DATASET = 'mabble.module.type.dataset';
export const MBL_TYPE_ERROR = 'mabble.type.error';
export const MBL_TYPE_FORM_CAPTURE_V0 = 'form-capture';
export const MBL_TYPE_FORM_CAPTURE_V1 = 'app-form-capture';
export const MBL_TYPE_FORM_CAPTURE_V2 = 'mabble.data.form-capture';
export const MBL_TYPE_GM_APPLICATION_REVIEW = 'za.co.mabble.gm.application.review';
export const MBL_TYPE_GM_GEARING = 'sec.usc.grant.application.pct.grid';
export const MBL_TYPE_GM_REVIEW_CONFIG = 'za.co.mabble.gm.review.config';
export const MBL_TYPE_OE_ERROR = 'za.co.mabble.oe.error';
export const MBL_TYPE_GM_COMMUNICATIONS_HISTORY = 'za.co.mabble.gm.communications.history';
export const MBL_TYPE_INSTANCE = 'mabble.module.type.instance';
export const MBL_TYPE_KBMS_CONTENT = 'mabble.module.type.kbms.content';
export const MBL_TYPE_MENU_ITEM = 'mabble.module.type.menu-item';
export const MBL_TYPE_ORGANISATION = 'mabble.module.type.organisation';
export const MBL_TYPE_QUESTION_TAGS = 'mabble.type.question.tags';
export const MBL_TYPE_SKIP_RULES = 'skip-rules';
export const MBL_TYPE_SKIP_RULES_V2 = 'mabble.type.skip-rules.index';
export const MBL_TYPE_TEMPLATE = 'mabble.module.type.template';
export const MBL_TYPE_USC_GRANT_APPLICATION = 'sec.usc.grant.application.wf';
export const MBL_TYPE_USER_GM_COORDINATOR = 'mabble.type.user.gm.coordinator';
export const MBL_TYPE_USER_GM_REPORTS = 'mabble.type.user.gm.reports';
export const MBL_TYPE_USER_PROFILE = 'mabble.module.type.user.profile';
export const MBL_TYPE_USER_TAGS = 'mabble.type.user.tags';
export const MBL_TYPE_WIDGET = 'mabble.module.type.widget';
export const MBL_TYPE_WORKFLOW = 'mabble.module.type.workflow';
export const MBL_TYPE_WP_CCH_U = 'mabble.type.wordpress.cache.users';
export const MBL_TYPE_KBMS_CONFIG = 'mabble.type.kbms.configuration';
export const SATRUST_TYPE_KBMS_CONFIG = 'mabble.type.kbms.configuration';

export const MBL_EVENT_MAIL_GC_TO_PARTNER = 'mabble.event.mail.gc.to.partner';
export const MBL_EVENT_MAIL_GM_TO_PARTNER = 'mabble.event.mail.gm.to.partner';
export const MBL_EVENT_MAIL_PARTNER_TO_GC = 'mabble.event.mail.partner.to.gc';
export const MBL_EVENT_MAIL_GM_TO_GC = 'mabble.event.mail.gm.to.gc';
export const MBL_EVENT_MAIL_GC_TO_GM = 'mabble.event.mail.gc.to.gm';

export const MBL_KEY_DATALOADER = 'mabble.configuration.module.dataloader';
export const MBL_KEY_FILE_UPLOAD = 'mabble.data.file-upload';
export const MBL_KEY_FORM_DEFINITION = 'mabble.data.form-definition';
export const MBL_KEY_MODULES = 'mabble.configuration.module';
export const MBL_KEY_NOTE = 'mabble.data.note';
export const MBL_KEY_QUESTION_NOTE = 'mabble.data.question.note';
export const MBL_KEY_USER_MANAGEMENT = 'mabble.data.module.user.management';

export const MBL_STATUS_ARCHIVED = 'mabble.status.archived';
export const MBL_STATUS_DELETED = 'mabble.status.deleted';
export const MBL_STATUS_DRAFT = 'mabble.status.draft';
export const MBL_STATUS_OFF = 'mabble.status.off';
export const MBL_STATUS_READY = 'mabble.status.ready';

export const MBL_USC_GRANT_APPLICATION = 'sec.usc.grant.application.wf';
export const MBL_TIGER_GRANT_APPLICATION = 'sec.tiger.grant.application.wf';
export const MBL_TIGER_APPLICATION_REVIEW = 'sec.tiger.grant.application.review';

export const MBL_WIDGET_STYLE_A = 'mabble.configuration.widget.style.A';
export const MBL_WIDGET_STYLE_B = 'mabble.configuration.widget.style.B';
export const MBL_WIDGET_STYLE_C = 'mabble.configuration.widget.style.C';
export const MBL_WIDGET_STYLE_CARD = 'mabble.configuration.widget.style.card';
export const MBL_WIDGET_STYLE_COMPONENT_RENDER = 'mabble.configuration.widget.style.component';
export const MBL_WIDGET_STYLE_D = 'mabble.configuration.widget.style.D';
export const MBL_WIDGET_STYLE_E = 'mabble.configuration.widget.style.E';
export const MBL_WIDGET_STYLE_LINK = 'mabble.configuration.widget.style.link';
export const MBL_WIDGET_STYLE_NAV_ITEM = 'mabble.configuration.widget.style.nav-item';
export const MBL_WIDGET_STYLE_TOGGLE = 'mabble.configuration.widget.style.toggle';
export const MBL_WIDGET_TYPE_CARD = 'mabble.configuration.widget.type.card';
export const MBL_WIDGET_TYPE_COMPONENT = 'mabble.configuration.widget.type.component';
export const MBL_WIDGET_TYPE_DATAFLOW = 'mabble.configuration.widget.type.dataflow';
export const MBL_WIDGET_TYPE_DATALOADER = 'mabble.configuration.widget.type.dataloader';
export const MBL_WIDGET_TYPE_LINK = 'mabble.configuration.widget.type.link';
export const MBL_WIDGET_TYPE_ROUTED = 'mabble.configuration.widget.type.routed';
export const MBL_WIDGET_TYPE_WIDGET = 'mabble.configuration.widget.type.widget';


export const MBL_WORKFLOW_CAPTURE_STEP_COMPONENT_REF = 'app-workflow-form-capture';
export const MBL_WORKFLOW_CONTENT_STEP_COMPONENT_REF = MBL_TYPE_CONTENT;
export const MBL_WORKFLOW_STEP_COMPONENT_STATUS_ACTIVE = 'active';
export const MBL_WORKFLOW_STEP_COMPONENT_STATUS_INACTIVE = 'inactive';


/**
 * create a serialised json string from any given ObjectEntity<any>...
 */
export const oevd = (oe: ObjectEntity<any>): string => {
  const d = oe.data;
  const v = oe.value;
  oe.data = undefined;
  oe.value = undefined;
  return JSON.stringify(oe);
};

/**
 * Serialise the object for API payload;
 */
export const oevdoe = (oe: ObjectEntity<any>): ObjectEntity<any> => {
  const v = oe.value;
  oe.data = oevd(oe);
  oe.value = v;
  return oe;
};

/**
 * model Object Entity; starts the model lifecycle.
 *
 * Deserialise any embedded payload data.
 */
export const moe = (oe: ObjectEntity<any>): ObjectEntity<any> => {
  const deserialised = (oe.data && (JSON.parse(oe.data) || {})) || {};
  const result = oe;
  result.value = undefined;
  result.data = undefined;
  Object.keys(deserialised).forEach((moek) => {
    if (moek !== 'id' && moek !== '_mblVersion' && moek !== 'data') { // keep this pristine to the api given value
      result[moek] = deserialised[moek];
    }
  });
  return result;
};

/**
 * End Of model lifecycle hook will translate the JS model into the SAB API ObjectEntity<T>
 *
 * Serialise properties into data attribute.
 */
export const eom = (oe: ObjectEntity<any>): ObjectEntity<any> => {
  return oevdoe(oe);
};


export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export interface Identifiable {
  chosen?: boolean;
  description?: string;
  id?: number;
  _mblId?: string;
  name?: string;
  type?: string;
}

export interface Named extends Identifiable {
  other?: any;
}


export interface Oris extends Identifiable {
  word: string;
  label: string;
  value: any;
  input: boolean | Oris;
  derived: boolean | Oris;
  format: any | Oris;
  formula: any | Oris;
}

export interface OE extends Identifiable {
  _mblId?: string;
  _mblVersion?: number;
  id?: number;

  createdBy?: any;
  createdOn?: any;
  modifiedBy?: any;
  modifiedOn?: any;

  context?: string;
  identifier?: string;

  type?: string;
  contentType?: string;
}

export class UserInterfaceConfiguration {
  debugEnabled: boolean;
  sendmsgEnabled: boolean;
  defaultEditor: string;

  constructor(options?: any) {
    this.debugEnabled = options && options.debugEnabled || this.debugEnabled;
    this.sendmsgEnabled = options && options.sendmsgEnabled || this.sendmsgEnabled;
    this.defaultEditor = options && options.defaultEditor || 'plain';
  }
}


// --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --->
/**
 * The root mabble-object-entity definition.
 * This class typically, but not always, corresponds to a row on the oe_oris db table
 *
 * Many mini mabble-module types will extend from this base class
 */
export class ObjectEntity<T> implements OE {
  id?: number;
  _mblId: string;
  _mblVersion: number;

  createdBy?: string;
  createdOn?: number;
  modifiedBy?: string;
  modifiedOn?: number;

  context?: string;
  identifier?: string;

  type?: string;
  contentType?: string;

  data?: any;

  // not in the API
  hashed?: string;
  options?: any;
  properties?: any;
  value?: T; // does not travel across wire.

  constructor(options?: any, clear_mblId: boolean = false) {
    this.id = options && options.id || this.id;

    this._mblId = options && options._mblId || uuidv4();
    if (clear_mblId) {
      this._mblId = undefined;
    }
    this._mblVersion = options && options._mblVersion > 0 ? options._mblVersion : 0;

    this.createdBy = options && options.createdBy || this.createdBy;
    this.createdOn = options && options.createdOn || this.createdOn || (new Date()).getTime();
    this.modifiedBy = options && options.modifiedBy || this.modifiedBy;
    this.modifiedOn = options && options.modifiedOn || this.modifiedOn || (new Date()).getTime();

    this.context = options && options.context || this.context;
    this.identifier = options && options.identifier || this.identifier;

    this.type = options && options.type || this.type;

    this.contentType = options && options.contentType || this.contentType;

    this.data = options && options.data || this.data;

    this.hashed = options && options.hashed || this.hashed;
    this.value = options && options.value || this.value;

    moe(this as ObjectEntity<any>);
  }

  public hasChangedHash(): boolean {
    const currentHash = hash(this, {
      excludeKeys: function (key) {
        return key !== 'hash';
      }
    });
    return currentHash !== this.hashed;
  }

  public updateHash(): any {
    this.hashed = hash(this, {
      excludeKeys: function (key) {
        return key !== 'hash';
      }
    });
    return this;
  }
}

export class ObjectEntityDataList<T> extends ObjectEntity<T[]> {
  dataItems: T[];

  constructor(options?: any) {
    super(options);
    if (options && options.dataItems && options.dataItems.length) {
      this.dataItems = [...options.dataItems];
    } else {
      this.dataItems = [];
    }
  }
}

// codes file support
export class CodesFile extends ObjectEntity<CodesFile> implements OE {
  code?: string;
  description?: string;
  name?: string;
  parentId?: number;

  items?: any[];
  levels?: number;

  data?: any;
  value?: any;

  constructor(options?: any) {
    super(options);
    this.code = options && options.code || this.code;
    this.description = options && options.description || this.description;
    this.name = options && options.name || this.name;
    this.parentId = options && options.parentId || this.parentId;
  }

  get hasPayload(): boolean {
    return (this.items && this.items.length > 0);
  }

  get payload(): any[] {
    return this.items;
  }
}

export class CodesFileAndChildren {
  parent: CodesFile;
  children: CodesFile[];
}

// send msg wrapper
export class MblSendMsgPayload extends ObjectEntity<MblSendMsgPayload> {
  recipients: string[];
  messageBody: string;
  subject: string;

  constructor(options?: any) {
    super(options);
    this.recipients = options && options.recipients || this.recipients;
    this.messageBody = options && options.messageBody || this.messageBody;
    this.subject = options && options.subject || this.subject;

  }

}

// --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --->
// --->
// ---> Application Configuration
// --->


export class ApplicationFormBuilderConfiguration extends ObjectEntity<ApplicationFormBuilderConfiguration> implements OE {

  page: ApplicationComponentPage;

  route: {
    base: string,
    home: string
  };

  //
  section: {
    route: {
      base: string,
    }
  };
  questions: {
    route: {
      base: string;
    };
    config?: {
      answer?: {
        options?: {
          source?: {
            value?: any;
          }
        }
      }
    }
  };

  constructor(options?: any) {
    super(options);
    this.page = options && options.page;
    this.route = options && options.route;
    this.section = options && options.section;
    this.questions = options && options.questions;
  }

}

export class ApplicationFormCaptureConfiguration {
  page: ApplicationComponentPage;
  route: {
    base: string;
  };
  type?: string;

  constructor(options?: any) {
    this.page = options && options.page || this.page;
    this.route = options && options.route || this.route;
    this.type = options && options.type || this.type || MBL_TYPE_FORM_CAPTURE_V2;
  }

}

export class ApplicationSessionMenuOptionItem {
  fa_icons?: string;
  label: string;
  link: string;
  enabled: boolean;
  roles?: Array<string>;
}

export class ApplicationSessionMenuGroup {
  label: string;
  fa_icons: string;
  items?: Array<ApplicationSessionMenuOptionItem>;
}

export class ApplicationSessionMenuSegment {
  groups?: Array<ApplicationSessionMenuGroup>;
  items?: Array<ApplicationSessionMenuOptionItem>;
}

export class ApplicationSessionMenu {
  options: {
    showUserProfileMenu?: boolean,
    public?: {
      left?: ApplicationSessionMenuSegment,
      right?: ApplicationSessionMenuSegment,
    },
    secure?: {
      left?: ApplicationSessionMenuSegment,
      right?: ApplicationSessionMenuSegment
    },
    user: {
      items?: Array<ApplicationSessionMenuOptionItem>;
    }
  };

  constructor(options?: any) {
    this.options = options && options.options || this.options;
  }
}

export class ApplicationComponentPage extends ObjectEntity<ApplicationComponentPage> implements OE {
  breadcrumb?: {
    enabled?: boolean,
    reset?: boolean,
    label?: string,
    url?: string
  };
  bottom?: {
    text?: string
  };
  description?: string;
  header?: string;
  lead?: string;
  name?: string;

  constructor(options?: any) {
    super(options);
  }
}

export class ApplicationApi {
  base: string;
  endpoints?: {
    capture?: {
      root?: string,
    },
    content?: {
      file?: string;
      raw?: string;
      root?: string;
      index?: string;
    },
    codes?: string,
    form?: {
      definition?: {
        root: string
      }
    }
    geo?: {
      lookup?: string;
      uid?: string;
      pwd?: string;
      countries?: string;
    };
    gm?: {
      review: string;
      root: string;
    }
    lookup?: string,
    logout?: string,
    reports?: {
      embed: {
        root: string
      }
    },
    self?: string,
    users?: {
      profile?: string,
      root?: string,
      x?: string
      register: {
        path: string,
        msg: string,
      },
    }
  };
  host?: string;
  reports: {
    proxy: string
  };
  solrhost?: string;
  wphost?: string;

  constructor(options?: any) {
    this.base = options && options.base || this.base;
    this.endpoints = options && options.endpoints || this.endpoints;
    this.host = options && options.host || this.host;
    this.reports = options && options.reports || this.reports;
    this.solrhost = options && options.solrhost || this.solrhost;
    this.wphost = options && options.wphost || this.wphost;
  }

}

export class User extends ObjectEntity<User> implements OE, Named {

  firstName?: string;
  lastName?: string;
  username?: string = null;
  email?: string;

  picture?: string;

  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  enabled?: boolean;
  notes?: string;
  roles?: Array<string> = [];
  userStatus?: string;

  // Out only,  never provided inbound;
  password?: string;
  confirmed?: string;
  ba?: string = null;

  public static hasRole(role: string, assignedRoles: Array<string>): boolean {
    if (assignedRoles && assignedRoles.length > 0) {
      for (let i = 0; i < assignedRoles.length; i++) {
        if (assignedRoles[i] === role) {
          return true;
        }
      }
    }
    return false;
  }

  public static hasAnyRole(candidateRoles: Array<string>, assignedRoles: Array<string>): boolean {
    if (candidateRoles && candidateRoles.length > 0) {
      for (let i = 0; i < candidateRoles.length; i++) {
        if (User.hasRole(candidateRoles[i], assignedRoles)) {
          return true;
        }
      }
    }
    return false;
  }

  constructor(options?: any) {
    super(options);
    this.accountNonExpired = options && options.accountNonExpired || this.accountNonExpired;
    this.accountNonLocked = options && options.accountNonLocked || this.accountNonLocked;
    this.ba = options && options.ba || this.ba;
    this.confirmed = options && options.confirmed || this.confirmed;
    this.credentialsNonExpired = options && options.credentialsNonExpired || this.credentialsNonExpired;
    this.email = options && options.email || this.email;
    this.enabled = options && options.enabled || this.enabled;
    this.firstName = options && options.firstName || this.firstName;
    this.id = options && options.id || this.id;
    this.lastName = options && options.lastName || this.lastName;
    this.notes = options && options.notes || this.notes;
    this.password = options && options.password || this.password;
    this.picture = options && options.picture || this.picture;
    this.roles = options && options.roles || this.roles || ['ROLE_ANONYMOUS'];
    this.username = options && options.username || this.username;
    this.userStatus = options && options.userStatus || this.userStatus;
  }

  get name(): string {
    if (this.firstName && this.lastName) {
      return this.firstName + ' ' + this.lastName;
    }
    return this.username || this.email;
  }

  public hasAnyRole(): boolean {
    return !!(this.roles && this.roles.length);
  }
}

export class MabbleUserAccount extends User implements OE, Named {
  type = MBL_TYPE_USER_PROFILE;
  context = MBL_CTX_USER;

  contact?: { telephoneNumber: string; faxNumber: string; mobileNumber: string; };
  firstName: string;
  geo?: { location?: { lon: string; lat: string; } };
  lastName: string;
  private _user: User;

  constructor(options?: any) {
    super(options);
    this.contact = options && options.contact || this.contact;
    this.firstName = options && options.firstName || this.firstName;
    this.geo = options && options.geo || this.geo;
    this.lastName = options && options.lastName || this.lastName;
  }

  set linkedUser(user: User) {
    this._user = new User(user);
    this.identifier = this._user && String(this._user.id) || null;
  }

  get linkedUser(): User {
    return new User(this._user);
  }

  isLinkedToUser(): boolean {
    return !!this.identifier;
    // return !!(this._user && this._user.id);
  }
}

export class ApplicationSessionUser {
  auth_token?: string;
  data?: User;
  source?: any;

  public static applicationSessionUserTableColumns(): TableColumn[] {
    return [
      {prop: 'data.firstName', name: 'First Name'},
      {prop: 'data.lastName', name: 'Last Name'},
      {prop: 'data.email', name: 'EMail Address'},
      {prop: 'data.username', name: 'Username'},
      {prop: 'data.createdOn', name: 'Created On'},
      {prop: 'data.createdBy', name: 'Created By'}
    ];
  }

  constructor(options?: any) {
    this.auth_token = options && options.auth_token || undefined;
    this.data = new User(options && options.data || {username: 'Visitor', roles: ['ROLE_ANONYMOUS']});
    this.source = options && options.source;
  }

  public isAuthenticated(): boolean {
    return !!(this.auth_token
      && this.auth_token.length > 0
      && this.data
      && this.data.enabled
      && this.data.roles
      && this.data.roles.length > 0
      && this.data.username);
  }

  public hasRole(role: string): boolean {
    return this.data
      && this.data.roles
      && this.data.roles.length > 0
      && User.hasRole(role, this.data.roles);
  }

  public hasAnyRole(roles: Array<string>): boolean {
    return this.data
      && this.data.roles
      && this.data.roles.length > 0
      && User.hasAnyRole(roles, this.data.roles);
  }

}


// --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --->
// --->
// ---> Application Configuration
// --->
export class ApplicationSession {
  _mblId?: string;

  api?: ApplicationApi;
  m_api?: ApplicationApi;

  auth?: {
    supports?: string[],
    apiEndpoint?: string
  };

  client?: {
    a?: string,
    b?: string,
    c?: string,
  };
  clients?: Array<any>;
  config?: UserInterfaceConfiguration;

  components?: {
    app_configuration?: {
      page: ApplicationComponentPage
    }
    form: {
      builder?: ApplicationFormBuilderConfiguration;
      capture?: ApplicationFormCaptureConfiguration;
    },
    password_reset?: {
      page?: ApplicationComponentPage
    }
  };
  features?: {
    questionCategories: boolean;
    buildKBMSIndex: boolean;
  };
  menu?: ApplicationSessionMenu;
  user?: ApplicationSessionUser;
  mabbleUser?: ApplicationSessionUser;
  instance?: {
    state?: BehaviorSubject<InstanceConfiguration>;
    homeContentKey?: string;
    contentKeys?: string[];
    placeholders?: {
      [propertyName: string]: string;
    };
    labels?: {
      formBuilder: {
        questionType: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeValue: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeChoice: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeRange: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeFormula: {
          options: Array<{ key: string, value: string }>
        },
        gridRowSource: {
          options: Array<{ key: string, value: string }>
        },
        gridColumnSource: {
          options: Array<{ key: string, value: string }>
        },
        gridAnswerType: {
          options: Array<{ key: string, value: string }>
        }
      }
    }
  };
  defaults?: {
    home?: {
      blurb?: string,
      logo?: string,
      banner?: string,
      route?: string,
      title?: string,
    };
    developer?: {
      anchor?: string,
      blurb?: string
    };
    forgot?: {
      route: string
    };
    footer?: {
      anchor?: string,
      blurb?: string
    };
    login?: {
      x?: string
      route: string
    };
    logout?: {
      route: string
    };
    post?: {
      login?: {
        x?: string
        y?: string
        z?: string
        route: string
        admin: {
          route: string
        },
        user: {
          route: string
        }
      },
    };
    admin_roles?: string[];
    title: string;
  };
  errors?: {
    login: {
      fail: {
        credentials: string;
      }
    }
  };
  login?: {
    page: {
      breadcrumb: {
        label: string;
        url: string;
      },
      form: {
        model: {
          username: string;
          email: string;
          password: string;
          rememberMe: boolean;
        },
        schema: {
          'properties': {
            username: {
              title: string;
              type: string;
              format: string;
            },
            password: {
              title: string;
              type: string;
              widget: string;
            }
          },
          'required': Array<string>;
          'buttons': Array<{ 'id': string, 'label': string, 'class': string }>;
        }
      },
      name: string;
    }
  };
  version?: {
    major: number;
    minor: number;
    patch: number;
    label: string
  };
  ssitk?: string;

  constructor(options?: any) {
    this._mblId = options && options._mblId;

    this.instance = options && options.instance;
    if (this.instance) {
      this.instance.state = new BehaviorSubject<InstanceConfiguration>(null);
    }

    this.api = options ? new ApplicationApi(options.api || {}) : new ApplicationApi({});
    this.m_api = options ? new ApplicationApi(options.m_api || {}) : new ApplicationApi({});
    this.auth = options && options.auth;

    this.client = options && options.client || this.client;
    this.clients = options && options.clients || this.clients || [];

    this.components = options && options.components || this.components || {
      form: {
        builder: {},
        capture: {}
      }
    };
    this.components.form.builder =
      options
      && options.components
      && options.components.form
      && options.components.form.builder
      || this.components.form.builder
      || new ApplicationFormBuilderConfiguration({});

    this.components.form.capture =
      options
      && options.components
      && options.components.form
      && options.components.form.capture
      || this.components.form.capture
      || new ApplicationFormCaptureConfiguration({});


    this.config = options ? new UserInterfaceConfiguration(options.config) : new UserInterfaceConfiguration({});
    this.defaults = options && options.defaults;
    this.errors = options && options.errors;
    this.features = options && options.features || {
      questionCategories: false,
      buildKBMSIndex: false
    };
    this.login = options && options.login;
    this.menu = options ? new ApplicationSessionMenu(options.menu) : new ApplicationSessionMenu({});
    this.user = options ? new ApplicationSessionUser(options.user) : new ApplicationSessionUser({});
    this.mabbleUser = options ? new ApplicationSessionUser(options.mabbleUser) : new ApplicationSessionUser({});
    this.version = options && options.version;
    this.ssitk = options && options.ssitk;

  }

  set instanceConfiguration(_value: InstanceConfiguration) {
    this.instance.state.next(_value);
  }

  get instanceConfiguration(): InstanceConfiguration {
    return this.instance.state.getValue();
  }

  public observableState(): Observable<any> {
    return this.instance.state;
  }
}

// --->
// ---> Mabble-ised stuff.
// --->
export class MabbleStackItem {
  [k: string]: any;
}


// GMP-198 mabble meta data object for indexing.
export class MabbleDocumentDefinition extends ObjectEntity<MabbleDocumentDefinition> implements OE {
  file: string;
  absolutePath: string;
  internalName: string;

  [inindexAttributeName: string]: any;

  constructor(options?: any) {
    super(options);

    this.file = options && options.file || this.file;
    this.absolutePath = options && options.absolutePath || this.absolutePath;
    this.internalName = options && options.internalName || this.internalName;
  }

}


// --->
// ---> Module Definition.
// --->
export class MabbleModule<T> extends ObjectEntity<MabbleModule<T>> implements OE {
  description?: string;
  name?: string;
  status?: string;
  accessControl: {
    roles: string[]
  };

  constructor(options?: any) {
    super(options);
    this.accessControl = options && options.accessControl || this.accessControl;
    this.description = options && options.description || this.description;
    this.name = options && options.name || this.name;
    this.status = options && options.status || this.status;
    this.properties = options && options.properties || this.properties || {};
  }

}


export class MabbleModuleWidget extends MabbleModule<MabbleModuleWidget> implements OE {
  properties: {
    widget_active: boolean;
    widget_classes?: string;
    widget_color?: string
    widget_content?: string;
    widget_outline?: string;
    widget_route?: string;
    widget_selector?: string;
    widget_sequence?: number;
    widget_state?: string;
    widget_style?: string; // ['wdgt-a', 'wdgt-b', 'wdgt-c', 'wdgt-d', 'wdgt-e', ...],
    widget_text?: string;
    widget_title?: string;
    widget_type?: string; // ['link', 'container', 'route', 'content', 'form-capture', 'html', 'binary', 'datatable', 'search'],
    widget_type_from?: string; // for workflow steps, we stack the state, for now, 1 level deep;
    widget_width?: string; // ['3','4','12'] and now bootstrap columns,
  };

  constructor(options?: any) {
    super(options);
    this.properties = this.properties || options && options.properties || {};
    this.properties.widget_classes = options && options.properties && options.properties.widget_classes;
    this.properties.widget_color = options && options.properties && options.properties.widget_color;
    this.properties.widget_content = options && options.properties && options.properties.widget_content;
    this.properties.widget_outline = options && options.properties && options.properties.widget_outline;
    this.properties.widget_route = options && options.properties && options.properties.widget_route;
    this.properties.widget_selector = options && options.properties && options.properties.widget_selector;
    this.properties.widget_sequence = options && options.properties && options.properties.widget_sequence;
    this.properties.widget_state = options && options.properties && options.properties.widget_state || 'state-' + (new Date().getTime());
    this.properties.widget_style = options && options.properties && options.properties.widget_style;
    this.properties.widget_text = options && options.properties && options.properties.widget_text;
    this.properties.widget_title = options && options.properties && options.properties.widget_title;
    this.properties.widget_type = options && options.properties && options.properties.widget_type;
    this.properties.widget_width = options && options.properties && options.properties.widget_width || '12';
  }
}

export class MabbleModuleWidgetContainer extends MabbleModuleWidget implements OE {
  contains: MabbleModule<any>[];

  constructor(options?: any) {
    super(options);
    this.contains = options && options.contains || this.contains || [];
  }

  addItem(item: MabbleModule<any>): MabbleModuleWidgetContainer {
    this.contains = this.contains || [];
    this.contains.push(item);
    return this;
  }

}

export class MabbleModuleQuery extends MabbleModule<MabbleModuleQuery> implements OE {
  base?: MabbleModuleQuery;
  children?: MabbleModuleQuery[];
  key?: string;
  qbe?: ObjectEntity<any>; // todo 2017-11-06.115438 - this should all find a home...


  constructor(options?: any) {
    super(options);
    this.base = options && options.base || this.base;
    this.children = options && options.children || this.children;
    this.key = options && options.key || this.key;
    this.qbe = options && options.qbe || this.qbe;
  }
}

export class MabbleModuleDataset<T> extends MabbleModuleWidget implements OE {
  oeDataset?: ObjectEntity<T>[];
  oeFileUploadProperties: { upload_url: string; };
  oeFormDefinition?: FormDefinitionComponentModel;
  oeQbe: MabbleModuleQuery;

  constructor(options?: any) {
    super(options);
    this.oeDataset = options && options.oeDataset || this.oeDataset || [];
    this.oeFileUploadProperties = options && options.oeFileUploadProperties || this.oeFileUploadProperties;
    this.oeFormDefinition = options && options.oeFormDefinition || this.oeFormDefinition;
    this.oeQbe = options && options.oeQbe
      && new MabbleModuleQuery(options.oeQbe)
      || new MabbleModuleQuery({type: 'example'});
  }
}


export class MabbleModuleDataflow extends MabbleModuleDataset<FormCaptureComponentModel> implements OE {
  constructor(options?: any) {
    super(options);
  }

  get rootQuery(): ObjectEntity<any> {
    if (this.oeQbe && this.oeQbe.base && this.oeQbe.base.qbe) {
      const q = this.oeQbe.base.qbe;
      q._mblId = undefined;
      return q;
    }
    return new ObjectEntity<any>({context: 'no data found'});
  }
}

/**
 * This object is a class A example of correct use of type+context
 * The context stipulates the 'content key'
 */
export class MabbleModuleContentItem extends MabbleModule<MabbleModuleContentItem> implements OE {
  content_text?: string;
  content_html?: string;

  constructor(options?: any) {
    super(options);
    this.content_html = options && options.content_html || this.content_html;
    this.content_text = options && options.content_text || this.content_text;
    this.status = options && options.status || MBL_CTX_META_CONTENT_STATUS.new;
    this.type = MBL_TYPE_CONTENT;
  }
}

export class MabbleModuleDimension extends MabbleModuleWidgetContainer implements OE {
  dim_expression?: any;
  dim_label?: string;
  dim_order?: number;
  dim_type?: string;

  constructor(options?: any) {
    super(options);
    this.dim_expression = options && options.dim_expression || this.dim_expression;
    this.dim_label = options && options.dim_label || this.dim_label;
    this.dim_order = options && options.dim_order || this.dim_order;
    this.dim_type = options && options.dim_type || this.dim_type;
  }
}

export class InstanceConfiguration extends MabbleModule<any> implements OE {
  _statekey = 'zero';
  _state: { [p: string]: InstanceConfiguration } = {'zero': null};

  properties?: {
    instance_top_nav?: MabbleModule<any>[];
    instance_brand_logo_img?: string;
    instance_brand_banner_visible?: boolean;
    instance_brand_banner_img?: string;
    instance_left_sidebar_visible?: boolean;
    instance_left_sidebar?: MabbleModule<any>[];
    instance_left_sidebar_title?: string;
    instance_left_slide_menu?: MabbleModule<any>[];
    instance_right_slide_menu?: MabbleModule<any>[];
    instance_main?: MabbleModule<any>;
  };

  constructor(options?: any) {
    super(options);

    this.properties = options && options.properties || {};
    this.properties.instance_top_nav = options && options.properties && options.properties.instance_top_nav;
    this.properties.instance_brand_logo_img = options && options.properties && options.properties.instance_brand_logo_img;
    this.properties.instance_brand_banner_visible = options && options.properties && options.properties.instance_brand_banner_visible;
    this.properties.instance_brand_banner_img = options && options.properties && options.properties.instance_brand_banner_img;
    this.properties.instance_left_sidebar_visible = options && options.properties && options.properties.instance_left_sidebar_visible;
    this.properties.instance_left_sidebar = options && options.properties && options.properties.instance_left_sidebar;
    this.properties.instance_left_sidebar_title = options && options.properties && options.properties.instance_left_sidebar_title;
    this.properties.instance_left_slide_menu = options && options.properties && options.properties.instance_left_slide_menu;
    this.properties.instance_right_slide_menu = options && options.properties && options.properties.instance_right_slide_menu;
    this.properties.instance_main = options && options.properties && options.properties.instance_main;

    // save until last to get a clean copy
    this.addState('zero', this);
    if (options && options.initialStates) {
      const is: any[] = [...options.initialStates];
      is.forEach(value => this.addState(value.key, value.state));
    }

  }

  get hasMainContent(): boolean {
    return !!(this.properties && this.properties.instance_main);
  }

  get isSidebarVisible(): boolean {
    return this.properties && this.properties.instance_left_sidebar_visible;
  }

  get isBannerVisible(): boolean {
    return this.properties && this.properties.instance_brand_banner_visible;
  }

  set state(k: string) {

    if (k === this._statekey) { // no change;
      return;
    }

    this._state = this._state || {'zero': null};
    if (this._state.zero) {
      this.properties = _.cloneDeep(this._state['zero'].properties);
    }
    if (this._state[k]) {
      this._statekey = k;
      this._state[k].properties = _.merge(this.properties, this._state[k].properties);
    }
  }

  public addState(state: string, payload: InstanceConfiguration): InstanceConfiguration {
    payload['_state'] = undefined;
    this._state = this._state || {'zero': null};
    this._state = _.merge(this._state, {[state]: _.cloneDeep(payload)});
    return this;
  }

  public resetLeftSidebar(): void {
    this.properties = this.properties || {};
    this.properties.instance_left_sidebar = undefined;
    this.properties.instance_left_sidebar_title = undefined;
  }
}

export class MabbleActiveModule<T> extends MabbleModule<any> implements OE {
  _mblself?: MabbleActiveModule<T>;
  _mblsuper?: MabbleActiveModule<T>;

  dataset?: any;
  valueset?: any;

  constructor(options?: any) {
    super(options);
    this._mblself = options && options._mblself || undefined;
    this._mblsuper = options && options._mblsuper || undefined;
  }
}

export class DatasetDataloaderEntity extends ObjectEntity<any> implements OE {
  response?: any;
  status?: string;
  permalink?: string;
  file?: { name?: string; size?: number; };
  meta?: any;
  importHistory: { importedOn: number, importedBy: string, records: number }[];

  constructor(options?: any) {
    super(options);
    this.response = options && options.response || this.response;
    this.status = options && options.status || this.status;
    this.permalink = options && options.permalink || this.permalink;

    this.file = {};
    if (options && options.file) {
      this.file.name = options.file.name || '';
      this.file.size = options.file.size || 0;
    }

    this.meta = options && options.meta || this.meta;
    this.importHistory = options && options.importHistory || this.importHistory || [];
  }
}

// name, label, readonly, type, options[]
export class MblObjectFormControl {
  key: string;
  id?: string;

  name: string;
  order?: number;
  label: string;
  readonly: boolean;
  required: boolean;
  type: string; // "text|password|email|number|submit|date|datetime|datetime-local|month|color|range|search|tel|time|url|week"
  options: Array<any>; // dropdown select options only so far...

  object_controls?: Array<MblObjectFormControl>; // for object types we contain a set of controls for our object-properties

  formControl?: FormControl;

  model?: any;

  private _supportsHorizontal: boolean;

  constructor(options?: any) {
    this.key = uuidv4();
    this.name = options && options.name || this.key;
    this.label = options && options.label || this.name;

    this.model = options && options.model || this.model;

    this.object_controls = options && options.object_controls;
    this.options = options && options.options || undefined;
    this.readonly = options && options.readonly || false;
    this.required = options && options.required || false;
    this.type = options && options.type || 'label';

    // {
    //   value: '',
    //   disabled: options.readonly
    // }, Validators.compose([Validators.required])]);
    // },
    // const validators = [];
    // if (options && options.required) {
    //   validators.push(Validators.required);
    // }
    // const controlValidator = Validators.compose(validators.length > 0 ? validators : null);
    // this.formControl = fb.control(new FormControl(['', controlValidator]));
    // this.formControl.registerOnChange(() => {});

    this.supportsHorizontal = options && options.supportsHorizontal || false;
  }

  get valid() {
    return this.formControl && this.formControl.valid;
  }

  get value() {
    return this.model && this.name && this.model[this.name];
  }

  get supportsHorizontal() {
    return this._supportsHorizontal;
  }

  set supportsHorizontal(value: boolean) {
    this._supportsHorizontal = value;
  }
}

export class UserOrgMapping extends ObjectEntity<UserOrgMapping> implements OE {
  userId?: number;
  organizationIds?: number[];

  constructor(options?: any) {
    super(options);
    this.userId = options && options.userId || this.userId;
    this.organizationIds = options && options.organizationIds || this.organizationIds;
  }
}

export class GrantManagementReviewConfig extends ObjectEntity<GrantManagementReviewConfig> implements OE {
  shortlistThreshold?: number;

  constructor(options?: any) {
    super(options);
    this.shortlistThreshold = options && options.shortlistThreshold || this.shortlistThreshold;
  }
}

export const MBL_FORM_CONTROLS_MODULE: Array<MblObjectFormControl> = [
  new MblObjectFormControl({
    name: 'id',
    readonly: true,
    type: 'text',
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'name',
    type: 'text',
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'description',
    type: 'textarea',
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'status',
    readonly: false,
    type: 'dropdown',
    options: ['new', 'test', 'ready'],
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'type',
    readonly: false,
    type: 'dropdown',
    options: ['container', 'dataset', 'report', 'menu', 'event', 'debug'],
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    label: 'Properties',
    name: 'properties',
    type: 'object',
    object_controls: [
      new MblObjectFormControl({label: 'Log Image', name: 'properties.instance_brand_logo_img', type: 'text'}),
      new MblObjectFormControl({
        label: 'Left Sidebar Title',
        name: 'properties.instance_left_sidebar_title',
        type: 'text'
      }),
    ]
  }),
];

export const MBL_FORM_CONTROLS_MODULE_WIDGET: Array<MblObjectFormControl> = [
  new MblObjectFormControl({name: 'id', readonly: true, type: 'text', required: true, supportsHorizontal: true}),
  new MblObjectFormControl({name: 'css_classes', label: 'CSS', type: 'text', required: true, supportsHorizontal: true}),
  // new MblObjectFormControl({name: 'title', type: 'textarea', supportsHorizontal: true}),
  new MblObjectFormControl({name: 'title', label: 'Title', type: 'text', supportsHorizontal: true}),
  new MblObjectFormControl({
    name: 'status',
    readonly: false,
    type: 'dropdown',
    options: ['new', 'test', 'ready'],
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'style',
    readonly: false,
    type: 'dropdown',
    options: ['wdgt-a', 'wdgt-b', 'wdgt-c', 'wdgt-d', 'wdgt-e'],
    required: true,
    supportsHorizontal: true
  }),
  new MblObjectFormControl({
    name: 'type',
    readonly: false,
    type: 'dropdown',
    options: ['', 'container', 'datatable', 'search', 'search-crud'],
    required: true,
    supportsHorizontal: true
  }),
];

