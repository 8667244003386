import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';


// --
// --
// --
// --
// Internal logging wedge
export const LOG_STYLRD = {
  'ANNOUNCE': 'color:yellow;background-color:black;font-style:normal;',
  'DEBUG': 'color:brown;background-color:#E0C8FF;font-style:normal;',
  'DEBUG_ERM': 'color:orange;background-color:green;',
  'DEBUG_FAIL': 'color:red;background-color:#FDFF44;font-style:bold;',
  'DEBUG_MEH': 'color:orange;background-color:#ffffff;font-style:bold;',
  'DEBUG_OO': 'color:green;background-color:#ffffff;font-style:bold;',
  'DEBUG_SUCCESS': 'color:green;background-color:#FDFF44;font-style:bold;',
  'ERROR': 'color:white;background-color:red;font-style:bold;',
  'INFO': 'color:white;background-color:blue;font-style:bold;',
  'METHOD': 'color:#333333;background-color:inherit;font-style:bold;',
  'TRACE': 'color:yellow;background-color:brown;font-style:normal;',
  'WARN': 'color:orange;background-color:white;font-style:bold;',

  'app.component.ts': 'color:#ffffff;background-color:blue;',
  'array-of-questions-list.component.ts': 'color:black;font-style:bold;background-color:orange',
  'question-editor.component.ts': 'color:black;background-color:pink'
};
