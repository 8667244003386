import {Injectable} from '@angular/core';
import {ServiceSupportService} from '@services/service-support.service';
import {ObjectEntityService} from '@services/object-entity.service';
import {MabbleModuleWorkflow, Event, WorkflowStep, StepComponent} from '../model/workflow.model';
import {HttpClient} from '@angular/common/http';
import {ApplicationSessionService} from '@services/application-session.service';
import {FormDefinitionComponentModel} from '@forms/models/form-capture-component.model';

@Injectable()
export class WorkflowService extends ServiceSupportService {

  constructor(protected oes: ObjectEntityService,
              protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    super(sessionService, http);
  }

  returnFDCMArray(input: FormDefinitionComponentModel[]) {
  }

  listWorkflow(type: string, context: string, identifier: string): Promise<MabbleModuleWorkflow[]> {
    return this.oes.listByTypeContextAndId<MabbleModuleWorkflow>(type, context, identifier).then(
      oeresult => {
        const r: MabbleModuleWorkflow[] = [];
        oeresult.map(value => {
          r.push(new MabbleModuleWorkflow(value));
        });
        return r;
      }
    );
  }

  getFormDefinitions(type: string, context: string, identifier: string): Promise<FormDefinitionComponentModel[]> {
    return this.oes.listByTypeContextAndId<FormDefinitionComponentModel>(type, context, identifier).then(
      oeresult => {
        const result: FormDefinitionComponentModel[] = [];
        oeresult.map(value => {
          result.push(new FormDefinitionComponentModel(value));
        });
        return result;
      }
    );
  }

  getStep(workflow: MabbleModuleWorkflow, stepName: string, stepData?: string): WorkflowStep {
    const compSeqSort = function (a, b) {
      return a.componentSequence - b.componentSequence;
    };
    let stepObj: WorkflowStep;
    if (workflow.wfSteps && workflow.wfSteps.length > 0) {
      workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
        if (wfStep.stepName === stepName) {
          stepObj = wfStep;
          stepObj.stepData = stepData || stepObj.stepData;  // if no data is given, stay with what is already present.
          stepObj.stepComponents.sort(compSeqSort);
          return stepObj;
        }
      })
    }
    return stepObj;
  }

  getEvent(workflow: MabbleModuleWorkflow, eventName: string, eventData: string): Event {
    let eventObj: Event;
    // eventObj = workflow.resolve('ix-' + workflow.wfCurrentStepName + '.' + eventName);
    // eventObj.eventData = eventData;
    if (workflow.wfSteps && workflow.wfSteps.length > 0) {
      workflow.wfSteps.forEach((wfStep: WorkflowStep) => {
        if (wfStep.stepName === workflow.wfCurrentStepName) {
          if (wfStep.stepEvents && wfStep.stepEvents.length > 0) {
            wfStep.stepEvents.forEach((stepEvent: Event) => {
              if (stepEvent.eventName === eventName) {
                eventObj = stepEvent;
                eventObj.eventData = eventData;
                return eventObj;
              }
            })
          }
        }
      })
    }
    return eventObj;
  }

  getComponentDataById(workflowStep: WorkflowStep, mblId?: string, id?: string): any {
    let componentData: string;
    if (workflowStep.stepComponents && workflowStep.stepComponents.length > 0) {
      workflowStep.stepComponents.forEach((component: StepComponent) => {
        if (component.component_MblId === mblId || component.componentId === id) {
          componentData = component.componentData;
        }
      })
    }
    return componentData;
  }

  setupActivityCapture(userId: string) {
    //  get workflow for user
    //  add new json for activity capture step + indicator
    //  save workflow
  }

}
