/*
 * Data item definitions.
 * */

const nameSort = (a, b) => (a.name || 0) - (b.name || 0);
const orderSort = (a, b) => (a.order || 0) - (b.order || 0);
export const sequenceSort = (a, b) => (a.sequence || 0) - (b.sequence || 0);


/**
 * Sample payload from API server:
 * <pre><code>
 *     {"name":"The first form-builder","description":"A form-builder for testing","questions":null,"id":1
 * </code></pre>
 */
export class FormDefinition {
  id: number;
  name?: string;
  description?: string;
  options?: FormDefinitionOptions;
  questions?: Array<Question>;
  categories?: Array<QuestionCategory>;
  data?: FormDefinitionData;
}

export class FormDefinitionData {
  formulas?: Array<FormDefinitionFormula>;

  constructor() {
  }
}

export class FormDefinitionFormula {
  id: number;
  formulaLabel: string;
  formulaDefinition: string;
  operand1QuestionId: number;
  operand2QuestionId: number;
  operatorSymbol: string;
  valueType: string;
  unitOfMeasure: string;
}

export class QuestionsInSections {
  _mblId: string;
  id: string;
  label: string;
  sequence: number;
  questionType: string;
  answerType: string;
  section: FormDefinitionSection;
}

export class FormDefinitionSection {
  id: number;
  label: string;
  sequence: number;
  formDefinitionId: number;
  question: number;
}

export class FormDefinitionOptions {
  id?: number;
  questionPerPage = false;
  enableMobile = false;
  enableXlsExport = false;
  enableXlsImport = false;
  helpText?: string;
  indexAttributeName?: string;
}

export class QuestionCategory {
  data: string;
  name: string;

  constructor(options?: any) {
    this.data = options && options.data;
    this.name = options && options.name;
  }
}

export class Question {
  id?: number;
  code?: string;
  label?: string;
  weighting?: number;
  questionType?: string;
  answerType?: string;
  answerOptions?: Array<QuestionAnswerOption>;
  activity?: string;
  sourceCodesFileCode?: string;
  sourceQuestionId?: number;
  formId?: number;
  data?: string;
  boolQTlabel1?: string;
  boolQTlabel2?: string;
  categories?: Array<QuestionCategory>;
  formula?: string;
  skipRules?: any;
  validationRules?: ValidationRules;
  gridQuestion?: GridQuestion;
  allowComments?: boolean;
  section?: FormDefinitionSection;
  sequence?: number;
  nestedGridHeadings?: boolean;
  partnersIndicator?: boolean;

  constructor() {
  };
}

export class GridQuestion {
  addColumnSubtotal: boolean;
  addRowSubtotal: boolean;
  allowRowComments: boolean;
  allowValueRefresh: boolean;
  columnCodesFileCode: string;
  columnLabelsSource: string;
  columnList: Array<GridColumns>;
  gridAnswerType: string;
  questionId: number;
  rowCodesFileCode: string;
  rowLabelsSource: string;
  rowList: Array<GridRows>;

  constructor(options?: any) {
    this.addColumnSubtotal = options && options.addColumnSubtotal || this.addColumnSubtotal;
    this.addRowSubtotal = options && options.addRowSubtotal || this.addRowSubtotal;
    this.allowValueRefresh = options && options.allowValueRefresh || this.allowValueRefresh;
    this.allowRowComments = options && options.allowRowComments || this.allowRowComments;
    this.columnCodesFileCode = options && options.columnCodesFileCode || this.columnCodesFileCode;
    this.columnLabelsSource = options && options.columnLabelsSource || this.columnLabelsSource;
    this.columnList = options && options.columnList || this.columnList;
    this.gridAnswerType = options && options.gridAnswerType || this.gridAnswerType;
    this.questionId = options && options.questionId || this.questionId;
    this.rowCodesFileCode = options && options.rowCodesFileCode || this.rowCodesFileCode;
    this.rowLabelsSource = options && options.rowLabelsSource || this.rowLabelsSource;
    this.rowList = options && options.rowList || this.rowList;
  }
}

export class GridRows {
  id?: number;
  gridId?: number;
  label?: string;
  sequence?: number;
  weighting?: number;
}

export class GridColumns {
  id?: number;
  gridId?: number;
  label?: string;
  sequence?: number;
  weighting?: number;
}

export class QuestionAnswerOption {
  questionId: string;
  id: number;
  label: string; // --- IOption
  sequence: number;
  weighting: number;
  answer: any;
  disabled: boolean; // --- IOption
  value: string; // --- IOption
  public withState(id: number, label: string) {
    this.id = id;
    this.label = label;
    return this;
  };
}

export class ValidationRules {
  id: number;
  questionId: number;
  mandatory: boolean;
  firstInputMin: any;
  firstInputMax: any;
  secondInputMin: any;
  secondInputMax: any;
  operator: string;
  operatorErrorMsg: string;
  targetQuestionMblId: string;


  constructor(options?: any) {
    this.id = options && options.id;
    this.questionId = options && options.questionId;
    this.mandatory = options && options.mandatory;
    this.firstInputMin = options && options.firstInputMin;
    this.firstInputMax = options && options.firstInputMax;
    this.secondInputMin = options && options.secondInputMin;
    this.secondInputMax = options && options.secondInputMax;
    this.operator = options && options.operator;
    this.targetQuestionMblId = options && options.targetQuestionMblId;
  }
}
