import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-textarea-widget',
  templateUrl: './sf-textarea-widget.component.html'
})
export class TextAreaWidgetComponent extends ControlWidget {
}
