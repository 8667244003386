import * as objectPath from 'object-path';
import {ApiResponse} from '@core/models/api-response.model';
import {
  ApplicationSession,
  eom,
  MabbleDocumentDefinition,
  MBL_CTX_SENDMSG,
  MblSendMsgPayload,
  moe,
  ObjectEntity
} from '@core/models/application-session.model';
import {ApplicationSessionService} from './application-session.service';
import {AuthService} from './auth.service';
import {FormCaptureComponentModel, QuestionComponentModel} from '@forms/models/form-capture-component.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, from as fromPromise} from 'rxjs';
import {ServiceSupportService} from './service-support.service';
import {FormCaptureService} from '@forms/services/form-capture.service';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';

export const mabbleInterpolate = (session: ApplicationSession, data: any): any => {
  const mabble_regex_1 = /\[(.*?)\]/;
  const mabble_regex_2 = /\\[.*]/;
  const mabble_regex_3 = /(\[).+?(\])/;
  const mabble_regex_4 = /\[.*?\]/;

  const replacer = (match, p1, p2, p3, offset, string) => {
    // return objectPath.get(session, 'value._mblId');
    this.logger.trace('mabbleInterpolate.22',
      session,
      data,
      objectPath.get(session, match) || objectPath.get(session, 'value._mblI [d + JSON.stringifyoe' + ']'));
    return objectPath.get(session, match) || objectPath.get(session, 'value._mblId');
  };
  if (data) {
    if (typeof data === 'string') {
      while (data.match(mabble_regex_3)) {
        data = data.replace(mabble_regex_3, replacer);
      }
    } else if (Array.isArray && Array.isArray(data) || typeof data === 'object') {
      Object.keys(data).forEach((key, index) => data[key] = mabbleInterpolate(session, data[key]));
    }
  }
  return data;
};

export class FileUploadForm {
  private _files: Array<any>;

  constructor(files?: Array<any>) {
    this._files = files || [];
  }

  addFile(file: any) {
    this._files = this._files || [];
    this._files.push(file);
  }

  get files(): any[] {
    return this._files;
  }
}

export class ApiPage<T> {
  content: [any];
  totalElements: number;
  totalPages: number;
  last: boolean;
  live: boolean;
  size: number;
  number: number;
  sort: any;
  first: boolean;
  numberOfElements: number;
  qbe?: ObjectEntity<T>;

  constructor(options?: any) {
    this.content = options && options.content;
    this.totalElements = options && options.totalElements;
    this.totalPages = options && options.totalPages;
    this.last = options && options.last;
    this.live = options && options.live || false;
    this.size = options && options.size;
    this.sort = options && options.sort;
    this.first = options && options.first;
    this.numberOfElements = options && options.numberOfElements;
    this.qbe = options && options.qbe;
  }
}


/**
 * The object-entity service.
 */
@Injectable()
export class ObjectEntityService extends ServiceSupportService {

  /**
   * Instance configured application session
   */
  private uploaderURL: string;
  private sendmsgURL: string;
  protected url_local: string;

  constructor(protected logger: NGXLogger,
              protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient,
              public toastrService: ToastrService) {
    super(sessionService, http);
    this.sendmsgURL = this.url_ + '/oe-store/sendmsg/out';
    this.uploaderURL = this.url_ + this.api.endpoints.content.file;
    this.url_local = this.url_ + this.api.endpoints.content.root;
  }


  create(oe: ObjectEntity<any>): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._post<ApiResponse>(url, headers, eom(oe)).toPromise();
  }

  current(oeTypeKey: string, template: any): Promise<ObjectEntity<any>> {
    const oe = new ObjectEntity<any>({type: oeTypeKey, context: oeTypeKey, value: template});
    return this.getByType(oeTypeKey).toPromise().then(
      res => {
        if (res && res.length === 1) {
          return moe(new ObjectEntity(res[0]))
        } else {
          return this.create(eom(oe)).then(() => {
              return this.getByType(oeTypeKey).toPromise().then(created => {
                return moe(new ObjectEntity(created[0]));
              });
            }, err2 => {
              return err2;
            }
          );
        }
      },
      err => {
        return err;
      }
    ) as Promise<ObjectEntity<any>>;
  }


  getAll(): Promise<ObjectEntity<any>[]> {
    this.logger.trace('object-entity.service.ts#getAll:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise()
      .then(oel => {
        const oer: any[] = [];
        if (oel && oel.length > 0) {
          oel.map(i => oer.push(moe(i)));
        }
        return oer;
      });
  }

  getOne(id: number): Promise<ObjectEntity<any>> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + id;
    return super._get<ObjectEntity<any>>(url, headers).toPromise()
      .then((oe) => {
        this.logger.info('oe.service.getOne: fulfilled request ->', oe);
        return moe(oe);
      })
  }

  retOEL(oel: any[]): any[] {
    const oer: any[] = [];
    if (oel && oel.length > 0) {
      oel.map(i => oer.push(moe(i)));
    }
    return oer;
  }

  getByType(type: string): Observable<any[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return fromPromise(super._get<any[]>(url, headers).toPromise()
      .then(oel => {
        return this.retOEL(oel);
      }));
  }

  getTyped<T>(type: string): Observable<T[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return fromPromise(super._get<ObjectEntity<T>[]>(url, headers).toPromise()
      .then(oel => {
        return this.retOEL(oel);
      }));
  }

  grantApplicationList(page?: number, size?: number, currentSteps?: any[]): Promise<ApiPage<ObjectEntity<any>[]>> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_ + '/grant-management/grant-application/' + (page || '0') + '/' + (size || '15') + '?currentSteps=' + currentSteps;
    return super._get<ApiPage<ObjectEntity<any>[]>>(url, headers).toPromise()
      .then(result => {
        return result;
      });
  }

  gaDashboardTotals(): Promise<ApiPage<ObjectEntity<any>>> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_ + '/grant-management/dashboard-totals';
    return super._get<ApiPage<ObjectEntity<any>>>(url, headers).toPromise()
      .then(result => {
        return result;
      });
  }

  addToFinalShortlist(idList) {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_ + '/grant-management/addToFinalShortlist';
    return super._put<ObjectEntity<any>>(url, headers, idList).toPromise().then(
      fulfilled => {
        this.logger.info('oe.service.addToFinalShortlist: fulfilled request ->', fulfilled);
        return fulfilled;
      },
      response => {
        this.logger.error('oe.service.addToFinalShortlist: failed to complete request ->', response);
        // TODO we want to save the failed data to oe with type = MBL_TYPE_OE_ERROR
        // const data = new ObjectEntity({
        //   data: idList,
        //   type: MBL_TYPE_OE_ERROR,
        //   context: this.application.ssitk
        // });
        // super._post(this.url_local, headers, data);
        if (response && response.status === 409) {
          this.toastrService.error(
            'Conflict! Item has been updated by another user, unable to save. Please try again',
            'Error! - Save Failed',
            {
              timeOut: 0,
              closeButton: true
            }
          );
        }
        return Promise.reject(response);
      }
    );
  }

  removeFromFinalShortlist(idList) {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_ + '/grant-management/removeFromFinalShortlist';
    return super._put<ObjectEntity<any>>(url, headers, idList).toPromise().then(
      fulfilled => {
        this.logger.info('oe.service.removeFromFinalShortlist: fulfilled request ->', fulfilled);
        return fulfilled;
      },
      response => {
        this.logger.error('oe.service.removeFromFinalShortlist: failed to complete request ->', response);
        // TODO we want to save the failed data to oe with type = MBL_TYPE_OE_ERROR
        // const data = new ObjectEntity({
        //   data: idList,
        //   type: MBL_TYPE_OE_ERROR,
        //   context: this.application.ssitk
        // });
        // super._post(this.url_local, headers, data);
        if (response && response.status === 409) {
          this.toastrService.error(
            'Conflict! Item has been updated by another user, unable to save. Please try again',
            'Error! - Save Failed',
            {
              timeOut: 0,
              closeButton: true
            }
          );
        }
        return Promise.reject(response);
      }
    );
  }

  /**
   * If the appication parameters have enabled the KBMS features then this function
   * will build an object for the API MabbleDocumentDefinition indexer.
   *
   * @param {FormCaptureComponentModel} formCaptureComponentModel
   * @return {Promise<FormCaptureComponentModel>}
   */
  index(formCaptureComponentModel: FormCaptureComponentModel): Promise<FormCaptureComponentModel> {
    const extractAnswerValue = (question: QuestionComponentModel): any => {
      return question && question.answer && question.answer.length && question.answer.length === 1
        ? FormCaptureService.resolveAnswerValue(question)
        : null;
    };

    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + this.api.endpoints.content.index;
    const payload = new MabbleDocumentDefinition({
      id: formCaptureComponentModel.id
    });

    FormCaptureService.forEachQuestion(formCaptureComponentModel.definition, (question) => {
      if (question.indexAttributeName === 'Subject') { // todo MBL-553 its an array on solr
        payload[question.indexAttributeName] = [extractAnswerValue(question)];
      } else if (question.indexAttributeName === 'DocURL') { // todo MBL-553 it is not an array on solr
        payload[question.indexAttributeName] = extractAnswerValue(question)[0];
      } else if (question.indexAttributeName === 'City') { // todo MBL-553 it is not an array on solr
        payload[question.indexAttributeName] = extractAnswerValue(question)[0];
      } else {
        payload[question.indexAttributeName] = extractAnswerValue(question);
      }
    });

    this.logger.trace('object-entity.service.index: indexing object ->', payload);

    return super._post<ObjectEntity<MabbleDocumentDefinition>>(url, headers, payload).toPromise()
      .then(() => Promise.resolve(formCaptureComponentModel));
  }


  listByContextAndId(context: string, identifier: string): Promise<ObjectEntity<any>[]> {
    this.logger.trace('object-entity.service.ts#listByContextAndId:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/by-context?context=' + context + '&identifier=' + identifier;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise().then(this.retOEL);
  }

  listByTypeContextAndId<T>(type: string, context: string, identifier: string): Promise<T[]> {
    this.logger.trace('object-entity.service.ts#listByTypeContextAndId:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/by-type-context-id?type=' + type + '&context=' + context + '&identifier=' + identifier;
    return super._get<T[]>(url, headers).toPromise().then(this.retOEL);
  }


  pbeGet(page: ApiPage<ObjectEntity<any>[]>): Promise<ApiPage<ObjectEntity<any>[]>> {
    return this.pbe(page.qbe, page.number, page.size);
  }

  pbeNext(page: ApiPage<ObjectEntity<any>[]>): Promise<ApiPage<ObjectEntity<any>[]>> {
    return this.pbe(page.qbe, page.number + 1, page.size);
  }

  pbePrev(page: ApiPage<ObjectEntity<any>[]>): Promise<ApiPage<ObjectEntity<any>[]>> {
    return this.pbe(page.qbe, page.number - 1, page.size);
  }

  pbe(example: ObjectEntity<any>, page?: number, size?: number): Promise<ApiPage<ObjectEntity<any>[]>> {
    this.logger.trace('object-entity.service.ts#qbe:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/qbe/' + (page || '0') + '/' + (size || '15');
    if (example.value && !example.data) {
      example.data = JSON.stringify(example.value);
    }
    return super._post<ApiPage<ObjectEntity<any>[]>>(url, headers, example).toPromise()
      .then(value => {
        value.qbe = example;
        if (value && value.content && value.content.length > 0) {
          value.content.map(i => moe(i));
        }
        return value;
      });
  }

  promiseByType(type: string): Promise<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise().then(oel => {
      if (oel && oel.length > 0) {
        oel.map(i => moe(i));
      }
      return oel;
    });

  }


  qbe(example: ObjectEntity<any>, page?: number, size?: number): Promise<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/qbe/' + (page || '0') + '/' + (size || '100');
    if (example.value && !example.data) {
      example.data = JSON.stringify(example.value);
    }
    this.logger.trace('object-entity.service: searching for QBE ', example);
    return super._post<ApiPage<ObjectEntity<any>[]>>(url, headers, example).toPromise()
      .then(value => {
        if (value && value.content && value.content.length > 0) {
          value.content.map(i => moe(i));
        }
        return value.content;
      });
  }

  qjp(parameters: { type: string, context: string, identifier: string, filters: Array<String> }): Promise<ObjectEntity<any>[]> {
    const {type, context, identifier, filters} = parameters;
    this.logger.trace('object-entity.service.ts#qjp:');
    this.logger.trace('object-entity.service.ts#qjp: /qjp/{type}/{context}/{identifier}');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = [this.url_local, 'qjp', type, context, identifier].join('/');
    return super._post<ObjectEntity<any>[]>(url, headers, filters).toPromise()
      .then(oel => {
        if (oel && oel.length > 0) {
          oel.map(i => moe(i));
        }
        return oel;
      });
  }


  remove(id: number): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + id;
    return super._remove(url, headers).toPromise();
  }


  save(oe: ObjectEntity<any>): Promise<ApiResponse> {
    if (oe && oe.id && oe.id !== 0) {
      return this.update(oe);
    } else {
      return this.create(oe);
    }
  }

  saveFormCapture(oe: ObjectEntity<any>): Promise<ApiResponse> {
    this.logger.trace('object-entity.service.ts: [*saveFormCapture]', oe);
    return this.save(oe);
  }

  search(term: string): Observable<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/search?name=' + encodeURI(term);
    return super._get<ObjectEntity<any>[]>(url, headers).pipe(map(r => r.map(oe => moe(oe))));
  }


  update(oe: ObjectEntity<any>): Promise<ObjectEntity<any>> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + oe.id;

    return super._put<ObjectEntity<any>>(url, headers, eom(oe)).toPromise().then(
      fulfilled => {
        this.logger.info('oe.service.update: fulfilled request ->', fulfilled);
        return fulfilled;
      },
      response => {
        this.logger.error('oe.service.update: failed to complete request ->', response);
        if (response && response.status === 409) {
          this.toastrService.error(
            'Conflict! Item has been updated by another user, unable to save. Please try again',
            'Error! - Save Failed',
            {
              timeOut: 0,
              closeButton: true
            }
          );
        }
        return Promise.reject(response);
      }
    );
  }

  uploadFilesA(event): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const url = this.uploaderURL;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      super._post(url, headers, formData).toPromise();
    }
    return Promise.reject('No file payload input provided');
  }

  uploadFilesB(event): Promise<ApiResponse> {
    const formData: FileUploadForm = new FileUploadForm();
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      for (let x = 0; x < fileList.length; x++) {
        formData.addFile(fileList.item(x));
      }
    }

    const headers = this.authService.getAuthenticatedHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const url = this.uploaderURL;
    return super._post<ApiResponse>(url, headers, formData).toPromise();
  }

  uploadFilesC(event): Promise<ApiResponse> {
    this.logger.trace('object-entity.service.ts:.uploadFiles: - [event]', event);
    const formData: FileUploadForm = new FileUploadForm();
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList.item(0);
      const fd: FormData = new FormData();
      fd.append('file', file, file.name);
      formData.addFile(fd);

      this.logger.trace('object-entity.service.ts:.uploadFiles: - [formData]', formData);
      const headers = this.authService.getAuthenticatedHeaders();
      headers.set('Content-Type', 'multipart/form-data');
      const url = this.uploaderURL;
      return super._post<ApiResponse>(url, headers, formData).toPromise();
    }
    return Promise.reject('No file payload input provided');
  }

  mailUser(recipient, msgBody, title, type, asHtml = false) {
    this.sendmsgURL = this.url_ + '/oe-store/sendmsg/out?asHtml=' + asHtml;
    const payload = new MblSendMsgPayload({
      type: type,
      context: MBL_CTX_SENDMSG,
      identifier: this.user.data.username,
      recipients: recipient,
      messageBody: msgBody,
      subject: (title === 'PEPFAR Community Grant Notification') ? title : this.application.defaults.title + ' ' + title
    });
    return this.sendmsg(payload).then(value => value);
  }

  sendmsg(payload: any): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.sendmsgURL;
    payload.context = MBL_CTX_SENDMSG;

    if (environment.production) {
      try {
        return super._post<ApiResponse>(url, headers, eom(payload)).toPromise().then(() => {
          return this.echoSendMsg(payload);
        });
      } catch (error) {
      }
    } else {
      // For testing purposes only
      // payload.messageBody = 'Testing...\n' + payload.recipients[0] + '\n\n' + payload.messageBody;
      // payload.recipients = ['luqmaan@8327.co.za'];
      return this.echoSendMsg(payload);
    }
  }

  echoSendMsg(payload: any): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.sendmsgURL;

    payload.messageBody = 'Recipients: ' + payload.recipients[0] + '\n\n' + payload.messageBody;
    payload.recipients = ['martin+mblbot@8327.co.za'];
    payload.subject = payload.subject + ' ssitk = ' + this.application.ssitk;
    try {
      return super._post<ApiResponse>(url, headers, eom(payload)).toPromise();
    } catch (error) {
      this.logger.trace('object-entity.service.ts:.echoSendMsg: - FAILED', error);
    }
  }
}
