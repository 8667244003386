import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-file-widget',
  templateUrl: './sf-file-widget.component.html'
})
export class FileWidgetComponent extends ControlWidget {

  constructor() {
    super();
  }
}
