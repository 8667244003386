
import {ApiResponse} from '@core/models/api-response.model';
import {ApplicationSessionService} from './application-session.service';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RegistrationForm} from '@user/models/registration-form-model';
import {ServiceSupportService} from './service-support.service';


@Injectable()
export class RegistrationService extends ServiceSupportService {

  protected url_registration: string;

  constructor(protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_registration = this.url_ + this.api.endpoints.users.root + '/registration';
  }

  create(applicationForm: RegistrationForm): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration;
    // Make it so #1.
    return super._post<ApiResponse>(url, headers, applicationForm).toPromise();
  }

  update(user: RegistrationForm): Promise<RegistrationForm> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration + '/' + user.id;
    // Make it so #1.
    return super._put<RegistrationForm>(url, headers, user).toPromise();
  }


  getAll(): Promise<RegistrationForm[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration;
    return super._get<RegistrationForm[]>(url, headers).toPromise(); // Make it so #1.
  }

  getOne(id: number): Promise<RegistrationForm> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration + '/' + id;
    return super._get<RegistrationForm>(url, headers).toPromise(); // Make it so #1.
  }


  remove(id: number): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration + '/' + id;
    // Make it so #1.
    return super._remove(url, headers).toPromise();
  }


  search(term: string): Observable<RegistrationForm[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_registration + '/search?term=' + encodeURI(term);
    // Make it so #1.
    return super._get<RegistrationForm[]>(url, headers);
  }


}
