import * as _ from 'lodash';
import {ApiResponse} from '@core/models/api-response.model';
import {
  ApplicationApi,
  ApplicationSession,
  ApplicationSessionUser, eom,
  MBL_TYPE_OE_ERROR,
  ObjectEntity
} from '@core/models/application-session.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {ApplicationSessionService} from './application-session.service';
import {takeWhile} from 'rxjs/operators';
import {catchError, retry} from 'rxjs/operators';

export abstract class ServiceSupportService implements OnDestroy {

  public static HTTP_HEADERS = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');


  protected api$$: Subscription;
  protected api$: Observable<ApplicationApi>;
  protected api: ApplicationApi;

  protected mabbleApi$$: Subscription;
  protected mabbleApi$: Observable<ApplicationApi>;
  protected mabbleApi: ApplicationApi;

  protected application$$: Subscription;
  protected application$: Observable<ApplicationSession>;
  protected application: ApplicationSession;

  protected user$$: Subscription;
  protected user$: Observable<ApplicationSessionUser>;
  protected user: ApplicationSessionUser;

  protected mabbleUser$$: Subscription;
  protected mabbleUser$: Observable<ApplicationSessionUser>;
  protected mabbleUser: ApplicationSessionUser;

  protected RETRY_COUNT = 1;
  protected alive: boolean;
  protected url_: string;
  protected mabbleUrl_: string;

  public static doReject(response: ApiResponse) {
    return Promise.reject(response);
  }

  constructor(protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    this.alive = true;

    // ---> mabble-host api
    this.mabbleApi$ = this.sessionService.observe('session.m_api');
    this.mabbleApi$$ = this.mabbleApi$
      .pipe(takeWhile(() => this.alive))
      .subscribe((mabbleApi => this.mabbleApi = mabbleApi));

    // --->
    this.api$ = this.sessionService.observe('session.api');
    this.api$$ = this.api$
      .pipe(takeWhile(() => this.alive))
      .subscribe((api => this.api = api));

    // --->
    this.application$ = this.sessionService.observe('session');
    this.application$$ = this.application$
      .pipe(takeWhile(() => this.alive))
      .subscribe((application => this.application = application));

    // --->
    this.user$ = this.sessionService.observe('session.user');
    this.user$$ = this.user$
      .pipe(takeWhile(() => this.alive))
      .subscribe(user => this.user = user);

    // --->
    this.mabbleUser$ = this.sessionService.observe('session.mabble.host.user');
    this.mabbleUser$$ = this.mabbleUser$
      .pipe(takeWhile(() => this.alive))
      .subscribe(user => this.mabbleUser = user);

    // --->
    this.url_ = this.api.host + this.api.base; // + this.api.endpoints...;
    this.mabbleUrl_ = this.mabbleApi.host;
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.api$$ && !this.api$$.closed) {
      this.api$$.unsubscribe();
    }
    if (this.mabbleApi$$ && !this.mabbleApi$$.closed) {
      this.mabbleApi$$.unsubscribe();
    }
    if (this.application$$ && !this.application$$.closed) {
      this.application$$.unsubscribe();
    }
    if (this.user$$ && !this.user$$.closed) {
      this.user$$.unsubscribe();
    }
  }

  protected _get<T>(url: string, headers: HttpHeaders): Observable<T> {
    return this.http.get<T>(url, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError(ServiceSupportService.doReject)
      );
  }

  protected _post<T>(url: string, headers: HttpHeaders, payload?: any): Observable<T> {
    return this.http.post<T>(url, payload, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError((err: any, caught: Observable<T>) => {
          this.handleSaveError(payload, headers);
          return Promise.reject(err);
        })
      );
  }

  protected _put<T>(url: string, headers: HttpHeaders, payload?: any): Observable<T> {
    return this.http.put<T>(url, payload, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError((err: any, caught: Observable<T>) => {
          this.handleSaveError(payload, headers);
          return Promise.reject(err);
        })
      );
  }

  protected _remove(url: string, headers: HttpHeaders): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(url, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError(ServiceSupportService.doReject)
      );
  }

  private handleSaveError(payload: any, headers: HttpHeaders) {
    const data = new ObjectEntity({
      type: MBL_TYPE_OE_ERROR,
      context: this.user.data.username,
      identifier: payload.id + ' ' + payload._mblVersion
    });
    data.properties = data.properties || {};
    data.properties = _.merge({}, data.properties, {
      original: payload,
      application: this.application,
      user: this.user,
      id: null,
      _mblVersion: 0
    });
    this._post(this.url_ + '/oe-store', headers, eom(data)).toPromise();
  };
}
