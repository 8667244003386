import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-integer-widget',
  templateUrl: './sf-integer-widget.component.html'
})
export class IntegerWidgetComponent extends ControlWidget {
}
