import {MabbleModule, OE} from '@core/models/application-session.model';
import {Workplan} from '@grants/model/grant-management.model';
import {AppScore} from '@workflow/services/scoring.service';

export const MBL_WF_STATUS_INPROGRESS = 'in progress';
export const MBL_WF_STATUS_NEW = 'mabble.wf.status.new';

export class MabbleModuleWorkflow extends MabbleModule<MabbleModuleWorkflow> implements OE {
  wfName?: string;
  wfDescription?: string;
  wfSteps?: Array<WorkflowStep>;
  wfStatus?: string;
  wfState?: string;
  wfCurrentStepName?: string;
  wfUserId?: string;
  wfAuditTrail?: Array<AuditRecord>;
  wfWorkplan?: Workplan;
  appScoring?: AppScore;
  applicationReview?: any;
  approval?: any;
  fundingHistory?: any;
  finalShortlist?: boolean;
  private index?: any = {};

  constructor(options?: any) {
    super(options);
    this.wfName = options && options.wfName || this.wfName;
    this.wfDescription = options && options.wfDescription || this.wfDescription;
    this.wfSteps = options && options.wfSteps || this.wfSteps;
    this.wfStatus = options && options.wfStatus || this.wfStatus;
    this.wfState = options && options.wfState || this.wfState;
    this.wfCurrentStepName = options && options.wfCurrentStepName || this.wfCurrentStepName;
    this.wfUserId = options && options.wfUserId || this.wfUserId;
    this.wfWorkplan = options && options.wfWorkplan || this.wfWorkplan;
    this.wfAuditTrail = options && options.wfAuditTrail || this.wfAuditTrail;
    this.appScoring = options && options.appScoring || this.appScoring;
    this.applicationReview = options && options.applicationReview || this.applicationReview;
    this.approval = options && options.approval || this.approval;
    this.fundingHistory = options && options.fundingHistory || this.fundingHistory;
    this.finalShortlist = options && options.finalShortlist || this.finalShortlist;
  }

  protected indexByKey() {
    this.index = {};
    if (this.wfSteps) {
      this.wfSteps.map(workflowStep => {
        if (workflowStep.stepEvents) {
          workflowStep.stepEvents.map(event => {
            this.index['ix-' + workflowStep.stepName + '.' + event.eventName] = event;
          });
        }
      });
    }
  }

  resolve(key: string): Event {
    if (Object.keys(this.index).length === 0) {
      this.indexByKey();
    }
    return this.index[key];
  }

  flush() {
    this.index = undefined;
  }
}

export class WorkflowStep {
  stepName?: string;
  stepComponents?: Array<StepComponent>;
  stepEvents?: Array<Event>;
  stepData?: String;
  stepConfig?: StepConfig;

  constructor(options?: any) {
    this.stepName = options && options.stepName || this.stepName;
    this.stepComponents = options && options.stepComponents || this.stepComponents || [];
    this.stepEvents = options && options.stepEvents || this.stepEvents || [];
    this.stepData = options && options.stepData || this.stepData;
    this.stepConfig = options && options.stepConfig || this.stepConfig;
  }

}

export class StepComponent {
  componentName?: string;
  componentStatus?: string;
  componentRef?: string;
  componentSequence?: number;
  componentId?: string;
  component_MblId?: string;
  componentData?: any;
  componentActivatedById?: string;
  componentConfig?: ConfigOptions;
  componentSavedId?: number;

  constructor(options?: any) {
    this.componentName = options && options.componentName || this.componentName;
    this.componentStatus = options && options.componentStatus || this.componentStatus;
    this.componentRef = options && options.componentRef || this.componentRef;
    this.componentSequence = options && options.componentSequence || this.componentSequence;
    this.componentId = options && options.componentId || this.componentId;
    this.component_MblId = options && options.component_MblId || this.component_MblId;
    this.componentData = options && options.componentData || this.componentData;
    this.componentActivatedById = options && options.componentActivatedById || this.componentActivatedById;
    this.componentConfig = options && options.componentConfig || this.componentConfig;
    this.componentSavedId = options && options.componentSavedId || this.componentSavedId;
  }
}

export class Event {
  eventName?: string;
  saveData?: boolean;
  eventData?: string;
  eventRules?: string;
  eventTargetStep?: string;

  constructor(options?: any) {
    this.eventName = options && options.eventName || this.eventName;
    this.eventData = options && options.eventData || this.eventData;
    this.eventRules = options && options.eventRules || this.eventRules;
    this.eventTargetStep = options && options.eventTargetStep || this.eventTargetStep;
  }
}

export class ConfigOptions {
  useCaptureButtons?: boolean;
}

export class StepConfig {
  useSaeButton?: boolean;
  useNextButton?: boolean;
  usePrevButton?: boolean;
}

export class AuditRecord {
  dateTime?: any;
  user?: any;
  auditLog?: string;
  auditData?: Array<any>;
}
