import {Component} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'app-sf-checkbox-widget',
  templateUrl: './sf-checkbox-widget.component.html'
})
export class CheckboxWidgetComponent extends ControlWidget {
}
