import {ArrayWidgetComponent} from './array/sf-array-widget.component';
import {CheckboxWidgetComponent} from './checkbox/sf-checkbox-widget.component';
import {FileWidgetComponent} from './file/sf-file-widget.component';
import {IntegerWidgetComponent} from './integer/sf-integer-widget.component';
import {ObjectWidgetComponent} from './object/sf-object-widget.component';
import {RadioWidgetComponent} from './radio/sf-radio-widget.component';
import {RangeWidgetComponent} from './range/sf-range-widget.component';
import {SelectWidgetComponent} from './select/sf-select-widget.component';
import {StringWidgetComponent} from './string/sf-string-widget.component';
import {TextAreaWidgetComponent} from './textarea/sf-textarea-widget.component';
import {MabbleWidgetRegistry} from '../../../mabble-module/mabble-widget-registry';

export class SchemaFormWidgetRegistry extends MabbleWidgetRegistry {
  constructor() {
    super();

    this.register('hidden', StringWidgetComponent);

    this.register('array', ArrayWidgetComponent);
    this.register('object', ObjectWidgetComponent);

    this.register('string', StringWidgetComponent);
    this.register('search', StringWidgetComponent);
    this.register('tel', StringWidgetComponent);
    this.register('url', StringWidgetComponent);
    this.register('email', StringWidgetComponent);
    this.register('password', StringWidgetComponent);
    this.register('color', StringWidgetComponent);
    this.register('date', StringWidgetComponent);
    this.register('date-time', StringWidgetComponent);
    this.register('time', StringWidgetComponent);

    this.register('integer', IntegerWidgetComponent);
    this.register('number', IntegerWidgetComponent);
    this.register('range', RangeWidgetComponent);

    this.register('textarea', TextAreaWidgetComponent);

    this.register('file', FileWidgetComponent);
    this.register('select', SelectWidgetComponent);
    this.register('radio', RadioWidgetComponent);
    this.register('boolean', CheckboxWidgetComponent);
    this.register('checkbox', CheckboxWidgetComponent);

    this.setDefaultWidget(StringWidgetComponent);
  }
}
