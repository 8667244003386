import {ApplicationSessionService} from './application-session.service';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ServiceSupportService} from './service-support.service';


@Injectable()
export class LookupGeoService extends ServiceSupportService {

  private serviceHeaders: HttpHeaders = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  private url_local: string;

  constructor(protected logger: NGXLogger,
              protected sessionService: ApplicationSessionService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_local = this.api.endpoints.geo.lookup;
  }

  countryList(): Promise<any[]> {
    const token = btoa(this.api.endpoints.geo.uid + ':' + this.api.endpoints.geo.pwd);
    const headers = this.serviceHeaders.set('Authorization', 'Basic ' + token);
    this.logger.trace('lookup-get.service:countryList: ', token, headers);
    // return super._get<any[]>(this.url_local + this.api.endpoints.geo.countries, headers)
    return this.http.get<any[]>(this.url_local + this.api.endpoints.geo.countries, {headers})
      .toPromise()
      .then(
        onfulfilled => {
          this.logger.trace('lookup-get.service:countryList: ', onfulfilled);
          return onfulfilled;
        }
      );
  }


}
