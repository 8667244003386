

import {ApiResponse} from '@core/models/api-response.model';
import {ApplicationSessionService} from './application-session.service';
import {AuthService} from './auth.service';
import {CodesFile} from '@core/models/application-session.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceSupportService} from './service-support.service';
import {SystemServiceService} from './system-service.service';


@Injectable()
export class LookupCodesService extends ServiceSupportService {

  protected url_local: string;

  constructor(protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_local = this.url_ + this.api.endpoints.codes;
  }

  // ------ Entity Specific;
  byCode(code: string): Promise<CodesFile[]> {
    return super._get<CodesFile[]>(this.url_local + '/by/code/' + code, this.authService.getAuthenticatedHeaders()).toPromise();
  }

  distinctCodes(): Promise<string[]> {
    return super._get<string[]>(this.url_local + '/codes', this.authService.getAuthenticatedHeaders()).toPromise();
  }

  parent(id: number): Promise<CodesFile> {
    return super._get<CodesFile>(this.url_local + '/parent/' + id, this.authService.getAuthenticatedHeaders()).toPromise();
  }

  children(id: number): Promise<CodesFile[]> {
    return super._get<CodesFile[]>(this.url_local + '/children/' + id, this.authService.getAuthenticatedHeaders()).toPromise();
  }

  // distinct list of all PARENT codes.
  distinctParentCodes(): Promise<string[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/codes';
    return super._get<string[]>(url, headers).toPromise();
  }

  // all PARENT objects
  distinctParentObjects(): Promise<CodesFile[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/parents';
    return super._get<CodesFile[]>(url, headers).toPromise();
  }

  // children for a given Parent id
  childrenByParentId(id: number): Promise<CodesFile[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/children/' + id;
    return super._get<CodesFile[]>(url, headers).toPromise();
  }

  // children for a given Parent code
  childrenByParentCode(code: string): Promise<CodesFile[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/by/code/' + code;
    return super._get<CodesFile[]>(url, headers).toPromise();
  }

  // --- --- --- ---
  // ------ Common CRUD;

  create(entity: CodesFile): Promise<ApiResponse> {
    return super._post<ApiResponse>(this.url_local, this.authService.getAuthenticatedHeaders(), entity).toPromise();
  }

  update(entity: CodesFile): Promise<CodesFile> {
    return super._put<CodesFile>(this.url_local + '/' + entity.id, this.authService.getAuthenticatedHeaders(), entity).toPromise();
  }

  updateAll(code: string, codes: CodesFile[]): Promise<CodesFile[]> {
    return super._post<CodesFile[]>(this.url_local + '/merge', this.authService.getAuthenticatedHeaders(), codes)
      .toPromise()
      .then(() => this.byCode(code));
  }

  getAll(): Promise<CodesFile[]> {
    return super._get<CodesFile[]>(this.url_local, this.authService.getAuthenticatedHeaders()).toPromise();
  }

  getOne(id: number): Promise<CodesFile> {
    return super._get<CodesFile>(this.url_local + '/' + id, this.authService.getAuthenticatedHeaders()).toPromise();
  }

  remove(id: number): Promise<ApiResponse> {
    return super._remove(this.url_local + '/' + id, this.authService.getAuthenticatedHeaders()).toPromise();
  }


}
