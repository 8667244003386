import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {ApplicationSession} from '@core/models/application-session.model';
import {environment} from '@environments/environment';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ApplicationSessionService} from '@services/application-session.service';
import {takeWhile} from 'rxjs/operators';

/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthenticatedGuard
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate, CanLoad, OnDestroy {

  protected app$$: Subscription;
  protected app$: Observable<ApplicationSession>;
  protected app: ApplicationSession;

  protected alive: boolean;
  protected isProduction: boolean;

  constructor(protected sessionService: ApplicationSessionService,
              protected router: Router) {
    this.alive = true;
    this.isProduction = environment.production;
    this.app$ = this.sessionService.observe('session');
    this.app$$ = this.app$.pipe(takeWhile(() => this.alive)).subscribe(app => this.app = app);
  }

  /**
   * True when user has appropriate roles
   * @method canActivate
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.doGuard((route.data as any).scopes);
  }

  /**
   * True when user has appropriate roles
   * @method canLoad
   */
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.doGuard((route.data as any).scopes);
  }

  doGuard(roles?: Array<string>): boolean {
    const authenticated = this.app.user.isAuthenticated();
    const authorized = roles && roles.length > 0 ? this.app.user.hasAnyRole(roles) : true;
    if (!authenticated || !authorized) {
      // redirect to sign in page if user is not authenticated
      this.router.navigateByUrl(this.app.defaults.login.route);
    }
    return authenticated && authorized;
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.app$$ && !this.app$$.closed) {
      this.app$$.unsubscribe();
    }
  }
}
