import {
  ApplicationSessionUser,
  MBL_CTX_USER,
  MBL_TYPE_WP_CCH_U,
  MBL_USER_SOURCE_WP,
  ObjectEntityDataList
} from '@core/models/application-session.model';
import {AuthService} from './auth.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {LOG_STYLRD} from './sa-logging.service';
import {NGXLogger} from 'ngx-logger';
import {ObjectEntityService} from '@services/object-entity.service';
import {ServiceSupportService} from './service-support.service';
import {SessionEvent, ApplicationSessionService} from './application-session.service';

@Injectable()
export class WordpressApiService extends ServiceSupportService implements OnDestroy {

  private ugh = {
    satrust: 'bWFiYmxlX2FkbWluaXN0cmF0b3I6Zno1Yk9jZW56YTZXKCZhIyRWSUZvMzhY',
    tgr: btoa('mabble_administrator:keepITsimpleAsPossibl3'),
    usc: 'bWFiYmxlX2FkbWluaXN0cmF0b3I6LnRoaXMgaXMgdGhlIHVzZXIudGhpcyBpcyB0aGUgQVBJIHVzZXIu'
  };
  private snakes = {
    satrust: 'YWRtaW5pc3RyYXRvcjplbHZpcw==',
    tgr: 'YWRtaW5pc3RyYXRvcjplbHZpcw==',
    usc: 'YWRtaW5pc3RyYXRvcjplbHZpcw==',
    dev: 'YWRtaW5pc3RyYXRvcjplbHZpcw=='
  };

  private mblClientId = this.snakes.dev;
  private opts = {headers: new HttpHeaders().set('Authorization', 'Basic ' + this.ugh.tgr)};
  private wphost = '';
  private userUrl = '/wp-json/wp/v2/users';

  protected url_local: string;

  private timerSubscription: any;

  private wpUsersSource = new BehaviorSubject<any>([]);
  public wpUsers = this.wpUsersSource.asObservable();

  public readonly ROLE_GRANT_MANAGER = ['ROLE_GRANT_MANAGER'];
  public readonly ROLE_GRANT_COORDINATOR = ['ROLE_GRANT_COORDINATOR'];
  public readonly ROLE_GRANT_USERS = this.ROLE_GRANT_MANAGER.concat(this.ROLE_GRANT_COORDINATOR);

  constructor(protected logger: NGXLogger,
              protected sessionService: ApplicationSessionService,
              protected oes: ObjectEntityService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_local = this.url_ + this.api.endpoints.users.root;
    this.opts.headers = new HttpHeaders().set('Authorization', 'Basic ' + this.ugh[this.application.ssitk]);
    this.wphost = this.api.wphost;
    this.logger.trace('wordpress-api.service.ts#: created service...', this.wphost);

    // Disabled timer for fetching users every 5 mins
    // const timer = Observable.timer(0, 300000); // fetch wp users every 5 mins
    // this.timerSubscription = timer.subscribe(() => {

    // comment out,  no need for users on tiger yet.
    this.getUsers();

    // });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // Disabled timer for fetching users every 5 mins
    // this.timerSubscription.unsubscribe();
  }

  public self(): Observable<any> {
    return this.http.get(this.wphost + this.userUrl + '/me?_envelope', this.opts);
  }

  public login(uid: number): Promise<any> {
    this.logger.trace('wordpress-api.service.login: [' + uid + ']');
    let ASSIGNED_ROLES = ['ROLE_USER'];

    const targetUrl = this.wphost + this.userUrl + '/' + uid + '?_envelope&context=edit';
    this.logger.trace('%cwordpress-api.service.ts#login: SSO Login start...', LOG_STYLRD.INFO, targetUrl);
    return this.http.get(targetUrl, this.opts)
      .toPromise()
      .then(
        (user: any) => {
          // this.logger.trace('%cwordpress-api.service.ts#login: SSO Login complete...', LOG_STYLRD.INFO, user);

          const grantManagementUser = this.filterGrantManagementUsers([user.body], this.ROLE_GRANT_USERS);
          if (grantManagementUser && grantManagementUser.length) {
            ASSIGNED_ROLES = [...ASSIGNED_ROLES, ...grantManagementUser[0].roles];
          }

          this.sessionService.set('session.user', new ApplicationSessionUser({
            auth_token: this.mblClientId,
            data: {
              id: uid,
              ba: this.mblClientId,
              email: user.body.id,
              enabled: true,
              firstName: user.body.name,
              lastName: '',
              picture: user.body.avatar_urls && user.body.avatar_urls['48'] || '',
              roles: ASSIGNED_ROLES,
              username: user.body.name,
              userStatus: 'ACTIVE',
            },
            source: {
              service: MBL_USER_SOURCE_WP,
              user: user
            }
          }));
          return of(new SessionEvent({
            activeRecord: uid,
            event: this.sessionService.get('session.user'),
            key: 'wordpress-api.service.ts#login'
          }));
        },
        (error: any) => {
          this.logger.trace('%c.Something went terribly wrong...', LOG_STYLRD.ERROR, error);
        });
  }

  public getUsers() {
    let users = [];
    const buildFromWordPress = (): Promise<any> => {
      const targetUrl = this.wphost + this.userUrl + '?context=edit&per_page=100&page=';
      let page = 1;
      let hasMoreRows = true;
      const fetchPagedUsers = (): Promise<any> => {
        return this.http.get(targetUrl + page++, this.opts).toPromise().then((data: any[]) => {
          const filteredData = data.filter(dat => { // remove the applicant/public users. Seeking only internal staff.
            return dat.roles && dat.roles.length && (dat.roles.findIndex(dr => dr === 'public_user' || dr === 'ROLE_GRANT_APPLICANT') < 0);
          });
          users = users.concat(filteredData);
          if (data.length < 1) {
            hasMoreRows = false;
            this.wpUsersSource.next(users);
            return;
          } else {
            return fetchPagedUsers();
          }
        })
      };
      return fetchPagedUsers();
    };
    const buildFromMabble = (): Promise<boolean> => {
      return this.oes.getByType(MBL_TYPE_WP_CCH_U).toPromise().then(
        value => {
          if (value && value.length && value[0]) {
            users = [...value[0].dataItems];
            this.wpUsersSource.next(users);
            return true;
          }
          return false
        },
        error => {
          return false;
        });
    };
    const buildFromGivenType = (type: string) => {
      switch (type) {
        case 'tgr':
        case 'usc': {
          buildFromMabble().then(done => {
            if (!done) {
              buildFromWordPress().then(
                onfulfilled => {
                  this.wpUsersSource.subscribe(nextUsers => {
                    if (nextUsers && nextUsers.length) {
                      const userCache = new ObjectEntityDataList({
                        context: MBL_CTX_USER,
                        dataItems: [...nextUsers],
                        identifier: '1',
                        type: MBL_TYPE_WP_CCH_U
                      });
                      this.oes.save(userCache);
                    }
                  });
                }
              );
            }
          });
          break;
        }
        default: {
          buildFromMabble();
        }
      }
    };

    buildFromGivenType(this.application.ssitk);

  }

  getUserById(id) {
    const targetUrl = this.wphost + this.userUrl + '/' + id + '?_envelope&context=edit';
    return this.http.get(targetUrl, this.opts).toPromise().then((data: any[]) => data);
  }

  putUser(user) {
    const id = user.id;
    const targetUrl = this.wphost + this.userUrl + '/' + id;
    return this.http.put(targetUrl, user, this.opts).toPromise().then((response: any) => response);
  }

  public filterGrantManagementUsers(users: any[], userRole: string[]) {
    return users.filter(user => {
      if (!user || !user.roles || !user.roles.length) {
        return false;
      }
      const roles = user.roles.filter((role: string) => userRole.indexOf(role) >= 0);
      return roles.length > 0;
    });
  }
}
