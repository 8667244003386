import {ApplicationSession} from 'app/core/models/application-session.model';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ApplicationSessionService} from '@services/application-session.service';
import {ServiceSupportService} from '@services/service-support.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@services/auth.service';
import {
  FormCaptureComponentModel,
  FormDefinitionComponentModel,
  FormSectionComponentModel,
  QuestionComponentModel
} from '@forms/models/form-capture-component.model';

const initialize2DArray = (w, h, val = null) =>
  Array.from({length: h}).map(() => Array.from({length: w}).fill(val));
const isArrayLike = val => {
  try {
    return !![...val];
  } catch (e) {
    return false;
  }
};

export class FormCaptureQuestionAndAnswers {
  fccm_mblId: string;
  createdBy: string;
  createdOn: number;
  modifiedBy: string;
  modifiedOn: number;

  [question: string]: any;

  constructor(options?: any) {
    if (options) {
      Object.keys(options).forEach(okey => {
        this[okey] = options[okey];
      });
    }
  }
}

@Injectable()
export class FormCaptureService extends ServiceSupportService {

  private endpoint = '';  // URL to the backend web api

  /**
   * Provides the derivative question.answer.value for any given {FormCaptureComponentModel} and {QuestionComponentModel}
   * combination.
   *
   * @param {QuestionComponentModel} question from which value is to be derived
   * @return {any} representing the answer value on this question object. The deduced question.answerValue s/be a primitive
   * value if possible.
   *
   * The following mappings have been taken from a function within object-entity.service.ts
   * <code>
   *      switch (question.answerType) {
   *          case 'TAGS': {
   *            if (question.answer[0].answerValue && question.answer[0].answerValue.length) {
   *              const a: any[] = question.answer[0].answerValue;
   *              a.forEach(i => results.push(i.label));
   *            }
   *            return results;
   *          }
   *          case 'CHECKBOX':
   *          case 'QUESTION_MULTI':
   *          case 'QUESTION_SINGLE':
   *          case 'LOOKUP_MULTI':
   *          case 'LOOKUP_SINGLE': {
   *            const options: [{ label: string, selected?: boolean }] = question.answer[0].answerValue;
   *            if (options && options.length) {
   *              options.forEach(r => {
   *                if (r.selected) {
   *                  results.push(r.label);
   *                }
   *              })
   *            }
   *            return results;
   *          }
   *          case 'LOOKUP_SINGLE_CASCADE': {
   *            results.push(question.answer[0].answerValue[2].selected);
   *            break;
   *          }
   *          case 'FILE': {
   *            const s = String(question.answer[0].answerValue[0]);
   *            const sa = s.split('/');
   *            if (sa && sa.length) {
   *              const filename = sa[sa.length - 1];
   *              results.push(this.api.host + '/resources/ext/' + filename);
   *            } else {
   *              results.push('error');
   *            }
   *            break;
   *          }
   *          default: {
   *            results.push(question.answer[0].answerValue)
   *          }
   *        }
   * </code>
   */
  public static resolveAnswerValue(question: QuestionComponentModel): any {
    if (question.answer && question.answer.length === 1) {
      switch (question.answerType) {
        case 'OPTIONSTABLE': {
          const selected: any[] = [];
          const answerValue = question.answer[0].answerValue;
          if (answerValue && answerValue.options) {
            answerValue.options.forEach(o => {
              if (o.selected) {
                selected.push(o.answer);
              }
            })
          }

          /*
           The derived value for this form-builder.question.[answer-type|input-type].
              In future, we can add options onto form-builder, define the value-types [number,date,string] and return
              appropriately based on that.
              This will allow us to then also do stuff like sum[values] to return a total/avg/etc

           For now,  we return the array of values [key-value] pairs via {QuestionAnswerOption}.
          */
          return selected;
        }
        case 'DATE': {
          if (question.answer[0].answerValue) {
            return String(question.answer[0].answerValue.year
              + '-' + question.answer[0].answerValue.month
              + '-' + question.answer[0].answerValue.day);
          }
          break;
        }
        case 'TAGS': {
          const results: string[] = [];
          if (question.answer[0].answerValue && question.answer[0].answerValue.length) {
            const a: any[] = question.answer[0].answerValue;
            a.forEach(i => results.push(i.label));
          }
          return results;
        }
        case 'CHECKBOX':
        case 'QUESTION_MULTI':
        case 'QUESTION_SINGLE':
        case 'LOOKUP_MULTI':
        case 'LOOKUP_SINGLE': {
          const results: string[] = [];
          const options: { label: string, selected?: boolean }[] = question.answer[0].answerValue;
          if (options) {
            if (isArrayLike(options)) {
              try {
                [...options].forEach(r => {
                  if (r.selected) {
                    results.push(r.label);
                  }
                });
              } catch (err) {
              }
            } else {
              results.push(String(options));
            }
          }
          return results;
        }
        case 'LOOKUP_SINGLE_CASCADE': {
          const results: string[] = [];
          results.push(question.answer[0].answerValue[2].selected);
          return results;
        }
        case 'REGION_SELECTION': {
          const results: string[] = [];
          results.push(question.answer[0].answerValue[2].selected);
          return results;
        }
        case 'FILE': {
          const results: string[] = [];
          const s = String(question.answer[0].answerValue[0]);
          const sa = s.split('/');
          if (sa && sa.length) {
            const filename = sa[sa.length - 1];
            results.push(filename);
          }
          return results;
        }
        case 'GRID': {
          if (question.answer[0].answerValue
            && question.answer[0].answerValue.length && question.answer[0].answerValue.length > 0) {
            const rows = initialize2DArray(
              question.answer[0].answerMeta.columnMetaData.length,
              question.answer[0].answerMeta.rowMetaData.length,
              undefined);
            question.answer[0].answerValue.forEach((cols, rix) => {
              cols.forEach((col, cix) => {
                rows[rix][cix] = FormCaptureService.resolveAnswerValue(col);
              });
            });
            return rows;
          }
          return undefined;
        }
        default: {
          return question.answer[0].answerValue;
        }
      }
    }
    return undefined;
  }

  public static forEachQuestion(definition: FormDefinitionComponentModel, perQuestionFn: (question) => void) {
    if (definition) {
      (definition.sections || []).forEach((section, six) => {
        (section.questions || []).forEach((question, qix) => {
          perQuestionFn(question);
        });
      });
    }
  }

  /**
   * Provides a question+answer map for any given {FormCaptureComponentModel}
   *
   * Note: This breakdown produces a JSON object.  For now, we are unable to deal with nested newline characters.
   *       The content of question.answer.** objects needs to be properly encoded and/or escaped before
   *       it's brought through here.
   *
   * @param {FormCaptureComponentModel} fccm (definition with .answers)
   * @param {string} key the key type for you requested map,  can be 'label', 'systemName' or any unique property on
   * the {QuestionComponentModel} object
   * @return {any} the {[key]: value} map result
   */
  public static makeIntoQuestionAnswers(fccm: FormCaptureComponentModel,
                                        key: string = 'label',
                                        extended: boolean = false): FormCaptureQuestionAndAnswers {
    const reply: FormCaptureQuestionAndAnswers = new FormCaptureQuestionAndAnswers({
      fccm_mblId: fccm._mblId
    });
    if (fccm && fccm.definition) {
      fccm.definition.sections.forEach((section: FormSectionComponentModel) => {
        section.questions.forEach(question => {
          const q: string = question[key] || question['systemName'];
          const a: string = FormCaptureService.resolveAnswerValue(question);
          reply[q] = String(a).trim()
            .replace(/\r?\n|\r/g, '... ')
            .replace('\n', '... ');
        });
      });
    }
    if (extended) {
      reply.createdBy = fccm.createdBy || fccm.capture && fccm.capture.createdBy || '';
      reply.createdOn = fccm.createdOn || fccm.capture && fccm.capture.createdOn || null;
      reply.fccm = fccm;
    }
    return reply;
  }

  constructor(protected logger: NGXLogger,
              protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.application$.subscribe((application: ApplicationSession) => {
      this.endpoint = application.api.host + application.api.base + application.components.form.capture.route.base;
    });

  }

  // noinspection JSMethodCanBeStatic
  /**
   * @deprecated use static class instance of this function.
   */
  public makeIntoQuestionAnswers(fccm: FormCaptureComponentModel, key: string = 'label'): any {
    return FormCaptureService.makeIntoQuestionAnswers(fccm, key);
  }

}
